import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Logo from "../../icons/logo-vector-white.svg"

import { useEffect, useState } from "react"
import { globalHistory } from "@reach/router"

const Style = styled.div`
  width: 100%;
  background: #222222;
  padding: 32px 0;
  font-family: "Work Sans";
  font-style: normal;
  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #dcdcdc;
    margin-bottom: 15px;
    width: 100%;
  }

  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #dcdcdc;
    text-decoration: none;
    margin-bottom: 15px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #dcdcdc;
  }

  @media (max-width: 1000px) {
    padding: 32px 0;
    padding-bottom: 16px;
  }
`

const ContactsStyle = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
  @media (max-width: 475px) {
    width: 80%;
  }
`
const Payment = styled.div`
  display: flex;
  width: auto;

  svg {
    margin: 4px;
    margin-bottom: 4px !important;
  }
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  svg {
    min-height: 36px;
    margin-bottom: 16px;
  }
  @media (max-width: 1000px) {
    margin-right: 0;
    height: max-content;
  }
  @media (max-width: 600px) {
    width: 200px;
    height: max-content;
    margin-right: 48px !important;
  }

  @media (max-width: 450px) {
    width: auto;
    height: auto;
  }
`
const TwoMatch = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  @media (max-width: 1000px) {
    flex-direction: column;
    width: auto;
    justify-content: flex-start;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: flex-start;
  }

  @media (max-width: 450px) {
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
  }
`

const BreakInMiddle = styled.div`
  flex-basis: 0%;

  @media (max-width: 1010px) {
    flex-basis: 100%;
  }
`

const ContactsLp = ({ wrapLocation }) => {
  const [links, setLinks] = useState(true)
  const [url, setUrl] = useState(wrapLocation ? wrapLocation.pathname : "/")
  console.log(wrapLocation)
  /// Detects URL change
  const [loc, setLoc] = useState(null)
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH" || action == "REPLACE" || action == "POP")
        setLoc(loc => (loc = !loc))
    })
  }, [setLoc])

  useEffect(() => {
    var loc = window.location.href
    if (loc.includes("lp1") && loc.includes("checkout")) {
      setLinks(false)
    } else {
      setLinks(true)
    }

    if (loc.includes("lp2") && loc.includes("checkout")) {
      setLinks(false)
    } else {
      setLinks(true)
    }

    // if (loc.includes("lp1")) {
    //   setUrl("/lp1")
    // }
    // if (loc.includes("lp2")) {
    //   setUrl("/lp2")
    // }
  }, [loc])

  return (
    <Style>
      <ContactsStyle links={links}>
        <TwoMatch>
          <Column>
            <Logo className="logo" />
            <a href="mailto: hello@pdfpro.com">hello@pdfpro.com</a>
          </Column>
        </TwoMatch>
        <TwoMatch>
          <Column>
            <Link to="/licensing">Licence Information</Link>
            <Link to="/dpa">DPA</Link>
          </Column>
        </TwoMatch>
        <TwoMatch>
          <Column>
            <Link to="/terms">Terms &amp; Conditions</Link>
            <Link to="/cookies">Cookie Policy</Link>
          </Column>
        </TwoMatch>
        <TwoMatch>
          <Column style={{ marginRight: `0` }}>
            <Link to="/privacy">Privacy Policy</Link>
            <Link
              to={`${
                wrapLocation ? "/" + url.split("/")[1] : ""
              }/refund-policy`}
            >
              Refund Policy
            </Link>
          </Column>
        </TwoMatch>
      </ContactsStyle>
    </Style>
  )
}

export default ContactsLp

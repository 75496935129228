import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import DropdownButton from "../dropdownButton"
import { checkStatus, billing } from "../../helpers/dateFormating"
import Close from "../../../icons/close-icon-popup.svg"
import HamburgerMenu from "../../../icons/hamburger_menu.svg"
import { Link, navigate } from "gatsby"
import { BuyButton } from "../header/shared/buyButton.style"
import { Navbar, Ul } from "./shared/navbarStyles.style"
import { GlobalAuthContext } from "../../../Contexts/GlobalAuthContext"

const LoggedIn = styled.div`
  .dropper {
    @media (max-width: 850px) {
      margin-top: -95px;
    }
  }

  .logged-different {
    @media (max-width: 850px) {
      position: relative;
      left: 35%;
      bottom: -45%;
    }

    @media (max-width: 530px) {
      left: 30%;
      bottom: -45%;
    }

    @media (max-width: 430px) {
      left: 25%;
      bottom: -45%;
    }
  }

  .hamburger-menu {
    display: none;
    height: 16px;
    width: 22px;
    cursor: pointer;
    /* this is old value */
    /* margin-right: 26px; */
    /* better design */
    margin-right: 0px;
    z-index: 10;

    @media (max-width: 850px) {
      display: block;
    }
  }

  .close-icon {
    display: none;
    height: 18px;
    width: 18px;
    cursor: pointer;
    margin-right: 0px;
    @media (max-width: 850px) {
      display: block;
    }
  }
`

function LoggedInNavbar({ showNav, setShowNav, closeMenu }) {
  const { user } = useContext(GlobalAuthContext)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    setIsLoading(false)
  }, [])

  function getState(state) {
    setIsLoading(state)
  }
  if (isLoading) {
    return null
  }
  return (
    <LoggedIn>
      <Navbar>
        {!showNav && (
          <HamburgerMenu
            className="hamburger-menu"
            onClick={() => {
              setShowNav(true)
            }}
          />
        )}
        {showNav && (
          <Close
            className="close-icon"
            onClick={() => {
              setShowNav(false)
            }}
          />
        )}
        <Ul show={showNav}>
          <li>
            <Link to="/blog" title="Blog" onClick={closeMenu}>
              Blog
            </Link>
          </li>
          {checkStatus() === false && billing() === "Trial" && (
            <li className="logged-different">
              <BuyButton
                onClick={() => {
                  navigate("/checkout")
                  closeMenu()
                }}
              >
                Checkout
              </BuyButton>
            </li>
          )}
          {checkStatus() === false && billing() !== "Trial" && (
            <li className="logged-different">
              <BuyButton
                onClick={() => {
                  navigate("/checkout")
                  closeMenu()
                }}
              >
                Checkout
              </BuyButton>
            </li>
          )}
          {checkStatus() !== false && billing() !== "Trial" && (
            <li className="logged-different">
              <BuyButton
                onClick={() => {
                  navigate("/app/manage-subscription")
                  closeMenu()
                }}
              >
                Checkout
              </BuyButton>
            </li>
          )}
          <li className="dropper">
            <DropdownButton
              style={{
                position: `relative`,
                top: `0.31rem`,
              }}
              title={user}
              getState={getState}
            ></DropdownButton>
          </li>
        </Ul>
      </Navbar>
    </LoggedIn>
  )
}

export default LoggedInNavbar

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Logo from "../icons/logo-vector-white.svg"
import Amazon from "../icons/payment/AmazonPay.svg"
import American from "../icons/payment/Amex.svg"
import Discover from "../icons/payment/Discover.svg"
import Mastercard from "../icons/payment/Mastercard.svg"
import PayPal from "../icons/payment/PayPal.svg"
import Visa from "../icons/payment/Visa.svg"
import { StaticImage } from "gatsby-plugin-image"
import { useEffect, useState } from "react"
import { globalHistory } from "@reach/router"

const Style = styled.div`
  width: 100%;
  background: #222222;
  padding: 32px 0;
  font-family: "Work Sans";
  font-style: normal;
  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #dcdcdc;
    margin-bottom: 15px;
    width: 100%;
  }

  a {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #dcdcdc;
    text-decoration: none;
    margin-bottom: 15px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #dcdcdc;
  }

  @media (max-width: 1000px) {
    padding: 32px 0;
    padding-bottom: 16px;
  }
`

const ContactsStyle = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
  @media (max-width: 475px) {
    width: 80%;
  }
  flex-wrap: wrap;
  gap: 32px;
`
const Payment = styled.div`
  display: flex;
  width: auto;

  svg {
    margin: 4px;
    margin-bottom: 4px !important;
  }
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  min-width: 196px;
  svg {
    min-height: 36px;
    margin-bottom: 16px;
  }
  @media (max-width: 1000px) {
    margin-right: 0;
    /* min-height: 220px; */
  }
  @media (max-width: 600px) {
    width: 200px;
    /* height: 250px; */
    margin-right: 48px !important;
  }

  @media (max-width: 450px) {
    width: auto;
    height: auto;
  }
`
const TwoMatch = styled.div`
  display: flex;
  width: max-content;
  justify-content: space-around;
  @media (max-width: 1000px) {
    flex-direction: column;
    width: auto;
    justify-content: flex-start;
    gap: 32px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 32px;
    height: auto;
    flex-direction: row;
    justify-content: flex-start;
  }

  @media (max-width: 450px) {
    width: 100%;
    /* margin-top: 0; */

    height: auto;
    flex-direction: column;
    justify-content: flex-start;
  }
`

const BreakInMiddle = styled.div`
  flex-basis: 0%;

  @media (max-width: 1010px) {
    flex-basis: 100%;
  }
`

const ContactsTools = ({ wrapLocation, onTool }) => {
  const [links, setLinks] = useState(true)
  const [url, setUrl] = useState(wrapLocation ? wrapLocation.pathname : "/")
  /// Detects URL change
  const [loc, setLoc] = useState(null)
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH" || action == "REPLACE" || action == "POP")
        setLoc(loc => (loc = !loc))
    })
  }, [setLoc])

  useEffect(() => {
    var loc = window.location.href
    if (loc.includes("lp1") && loc.includes("checkout")) {
      setLinks(false)
    } else {
      setLinks(true)
    }

    if (loc.includes("lp2") && loc.includes("checkout")) {
      setLinks(false)
    } else {
      setLinks(true)
    }

    // if (loc.includes("lp1")) {
    //   setUrl("/lp1")
    // }
    // if (loc.includes("lp2")) {
    //   setUrl("/lp2")
    // }
  }, [loc])

  return (
    <Style>
      <ContactsStyle links={links}>
        <TwoMatch>
          <Column>
            <Logo className="logo" />
            <a href="mailto: hello@pdfpro.com">hello@pdfpro.com</a>
            <p>
              PDF Pro Software Inc.<br></br> 1383 W. 8th Avenue<br></br>{" "}
              Vancouver, B.C. V6H 3W4, Canada<br></br>
            </p>
          </Column>
          <Column>
            <h2>Payments</h2>
            <Payment>
              <PayPal />
              <Visa />
            </Payment>
            <Payment>
              <Amazon />
              <American />
            </Payment>
            <Payment>
              <Discover />
              <Mastercard />
            </Payment>
          </Column>
        </TwoMatch>
        <TwoMatch>
          {links && (
            <Column>
              <h2>Support</h2>
              <a
                href="https://support.pdfpro.com/hc/en-us/categories/900000414286-Knowledge-Base"
                rel="noreferrer noopener"
                target="_blank"
              >
                Knowledge Base
              </a>
              <a
                href="https://support.pdfpro.com/hc/en-us/requests/new"
                rel="noreferrer noopener"
                target="_blank"
              >
                Submit a Ticket
              </a>
              <a
                href="https://support.pdfpro.com/hc/en-us/sections/4407459740569-FAQ"
                rel="noreferrer noopener"
                target="_blank"
              >
                FAQ
              </a>
              <a
                href="https://support.pdfpro.com/hc/en-us/sections/900002105743-Release-Notes"
                rel="noreferrer noopener"
                target="_blank"
              >
                Release Notes
              </a>
            </Column>
          )}
          {links && (
            <Column>
              <h2>Policy</h2>
              <Link to="/licensing">Licence Information</Link>
              <Link to="/dpa">DPA</Link>
              <Link to="/terms">Terms &amp; Conditions</Link>
              <Link to="/cookies">Cookie Policy</Link>
              <Link to="/privacy">Privacy Policy</Link>
              {!url?.includes("blog") && !onTool && (
                <Link
                  to={`${
                    wrapLocation ? "/" + url.split("/")[1] : ""
                  }/refund-policy`}
                >
                  Refund Policy
                </Link>
              )}
              {url?.includes("blog") ||
                (onTool && <Link to="/refund-policy">Refund Policy</Link>)}
            </Column>
          )}
        </TwoMatch>
        <TwoMatch>
          {links && (
            <Column>
              <h2>Products</h2>
              <Link to="/">PDF Pro</Link>
              <Link to="/pdf-online-tools">Online Tools</Link>

              {/* <h2 style={{ marginTop: "32px" }}>Online Tools</h2>
              <Link to="/merge-pdf">Merge PDF</Link>

              <Link to="/rotate-pdf">Rotate PDF</Link>
              <Link to="/extract-pdf-pages">Extract PDF</Link>
              <Link to="/delete-pdf-pages">Delete PDF</Link> */}
            </Column>
          )}
          {links && (
            <Column style={{ marginRight: `0` }}>
              <h2>Company</h2>
              <Link to="/about">About Us</Link>
              <Link to="/careers">Careers</Link>
              <Link to="/blog">Blog</Link>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ marginBottom: "4px" }}>Member of:</p>
                <Link
                  className="PDFA"
                  to="https://www.pdfa.org/member/pdf-pro-software-inc"
                >
                  <StaticImage
                    style={{ marginLeft: "0" }}
                    src="../images/PDFA-white.png"
                    quality={95}
                    width={64}
                  />
                </Link>
              </div>
            </Column>
          )}
        </TwoMatch>

        <TwoMatch>
          <Column>
            <Logo className="logo" />
            <a href="mailto: hello@pdfpro.com">hello@pdfpro.com</a>
            <p>
              PDF Pro Software Inc.<br></br> 1383 W. 8th Avenue<br></br>{" "}
              Vancouver, B.C. V6H 3W4, Canada<br></br>
            </p>
          </Column>
          <Column>
            <h2>Payments</h2>
            <Payment>
              <PayPal />
              <Visa />
            </Payment>
            <Payment>
              <Amazon />
              <American />
            </Payment>
            <Payment>
              <Discover />
              <Mastercard />
            </Payment>
          </Column>
        </TwoMatch>
        <TwoMatch>
          {links && (
            <Column>
              <h2>Support</h2>
              <a
                href="https://support.pdfpro.com/hc/en-us/categories/900000414286-Knowledge-Base"
                rel="noreferrer noopener"
                target="_blank"
              >
                Knowledge Base
              </a>
              <a
                href="https://support.pdfpro.com/hc/en-us/requests/new"
                rel="noreferrer noopener"
                target="_blank"
              >
                Submit a Ticket
              </a>
              <a
                href="https://support.pdfpro.com/hc/en-us/sections/4407459740569-FAQ"
                rel="noreferrer noopener"
                target="_blank"
              >
                FAQ
              </a>
              <a
                href="https://support.pdfpro.com/hc/en-us/sections/900002105743-Release-Notes"
                rel="noreferrer noopener"
                target="_blank"
              >
                Release Notes
              </a>
            </Column>
          )}
          {links && (
            <Column>
              <h2>Policy</h2>
              <Link to="/licensing">Licence Information</Link>
              <Link to="/dpa">DPA</Link>
              <Link to="/terms">Terms &amp; Conditions</Link>
              <Link to="/cookies">Cookie Policy</Link>
              <Link to="/privacy">Privacy Policy</Link>
              {!url?.includes("blog") && !onTool && (
                <Link
                  to={`${
                    wrapLocation ? "/" + url.split("/")[1] : ""
                  }/refund-policy`}
                >
                  Refund Policy
                </Link>
              )}
              {url?.includes("blog") ||
                (onTool && <Link to="/refund-policy">Refund Policy</Link>)}
            </Column>
          )}
        </TwoMatch>
        <TwoMatch>
          {links && (
            <Column>
              <h2>Products</h2>
              <Link to="/">PDF Pro</Link>
              <Link to="/pdf-online-tools">Online Tools</Link>

              {/* <h2 style={{ marginTop: "32px" }}>Online Tools</h2>
              <Link to="/merge-pdf">Merge PDF</Link>

              <Link to="/rotate-pdf">Rotate PDF</Link>
              <Link to="/extract-pdf-pages">Extract PDF</Link>
              <Link to="/delete-pdf-pages">Delete PDF</Link> */}
            </Column>
          )}
          {links && (
            <Column style={{ marginRight: `0` }}>
              <h2>Company</h2>
              <Link to="/about">About Us</Link>
              <Link to="/careers">Careers</Link>
              <Link to="/blog">Blog</Link>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ marginBottom: "4px" }}>Member of:</p>
                <Link
                  className="PDFA"
                  to="https://www.pdfa.org/member/pdf-pro-software-inc"
                >
                  <StaticImage
                    style={{ marginLeft: "0" }}
                    src="../images/PDFA-white.png"
                    quality={95}
                    width={64}
                  />
                </Link>
              </div>
            </Column>
          )}
        </TwoMatch>
      </ContactsStyle>
    </Style>
  )
}

export default ContactsTools

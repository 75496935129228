import React from "react"
import styled from "styled-components"
import Logo from "../../../icons/PDF-logo-vector.svg"
import { Link } from "gatsby"
import { useState, useEffect, useContext } from "react"
import { GlobalAuthContext } from "../../../Contexts/GlobalAuthContext"
import { StaticImage } from "gatsby-plugin-image"
import NotLoggedInNavbar from "./notLoggedInNavbar"
// import NotLoggedInNavbarB from "./notLoggedInNavbarB"
import LoggedInNavbar from "./loggedInNavbar"
import InformUser from "../../informModal/informUser"
import CountryDropdown from "../../checkoutNew/CountryDropdown"
import ToolsDropdown from "../ToolsDropdown"

const HeaderComponent = styled.header`
  display: flex;
  justify-content: center;
  height: 70px;
  width: 100%;
  background: #ffffff;

  border-bottom: 1px solid #e1e1e1;
  position: fixed;
  margin: 0px;
  padding: 0px;
  z-index: 12;

  .for-seo {
    position: absolute;
    top: 14.3px;
    visibility: hidden;
  }

  .desktop-navbar {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .mobile-navbar {
    display: none;
    @media (max-width: 1000px) {
      display: flex;
    }
  }
  .desktop {
    display: initial;
    @media (max-width: 1124px) {
      display: none;
    }
  }

  .mobile {
    display: none;
    @media (max-width: 1124px) {
      display: initial;
    }
  }

  .different {
    @media (max-width: 1000px) {
      margin-top: -65px;
    }
  }

  .dropper {
    @media (max-width: 1000px) {
      margin-top: -95px;
    }
  }

  .logged-different {
    @media (max-width: 1000px) {
      position: relative;
      left: 35%;
      bottom: -45%;
    }

    @media (max-width: 530px) {
      left: 30%;
      bottom: -45%;
    }

    @media (max-width: 430px) {
      left: 25%;
      bottom: -45%;
    }
  }

  .special {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 2px;
    left: -3rem;
    cursor: pointer;
    border: none;
    background: transparent;

    @media (max-width: 1124px) {
      left: 0rem;
    }

    @media (max-width: 869px) {
      left: -3rem;
    }

    @media (max-width: 768px) {
      left: 1.3rem;
      top: -1.5rem;
    }

    :hover {
      color: gray;
    }
  }

  .logo {
    height: 32px;
    width: 120px;
    //you removed fill on the svg so you can use the same logo for header&footer
    fill: #000000;
  }

  .hamburger-menu {
    display: none;
    height: 16px;
    width: 22px;
    cursor: pointer;
    /* this is old value */
    /* margin-right: 26px; */
    /* better design */
    margin-right: 0px;
    z-index: 10;

    @media (max-width: 1000px) {
      display: block;
    }
  }

  .close-icon {
    display: none;
    height: 18px;
    width: 18px;
    cursor: pointer;
    /* this is old value */
    /* margin-right: 26px; */
    /* better design */
    margin-right: 0px;
    @media (max-width: 1000px) {
      display: block;
    }
  }

  .checkout {
    background: #e44331;
    border-radius: 60px;
    color: white;
    :hover {
      color: white;
      background-color: red;
    }
  }
`

const PositionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  width: 90%;
  height: 100%;
  @media (max-width: 768px) {
    width: 95%;
  }
  @media (max-width: 1000px) {
    width: 100%;
    background: #fcfcfd;
    padding: 24px;
  }
`

const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PseudoModal = styled.div`
  height: 42px;
  @media (max-width: 1124px) {
    height: max-content;
  }
`

const MidSection = styled.div`
  display: flex;
  height: 69px;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-left: 32px;
`

function NewHeader() {
  const [showNav, setShowNav] = useState(false)
  const [location, setLocation] = useState(false)
  const [checkout, setCheckout] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const { isLoggedIn, isLoadingApp, showInfo } = useContext(GlobalAuthContext)
  let pathname = typeof window !== "undefined" ? window.location.pathname : ""

  function closeMenu() {
    setRefresh(!refresh)
    setTimeout(() => {
      setShowNav(false)
    }, 300)
  }

  const [justCheckout, setJustCheckout] = useState(null)

  let url = typeof window !== "undefined" ? window.location.pathname : "null"

  useEffect(() => {
    console.log("refresh: ", refresh)
    if (window.location.pathname === "/checkout") {
      setJustCheckout(true)
    } else {
      setJustCheckout(false)
    }
    if (
      window.location.pathname.includes("/app") &&
      !window.location.pathname.includes("/app/download")
    ) {
      setLocation(true)
    } else {
      setLocation(false)
    }

    if (
      (window.location.pathname.includes("checkout") &&
        window.location.pathname.includes("lp")) ||
      window.location.pathname.includes("checkout")
    ) {
      console.log("Na checkoutu")
      setCheckout(true)
    }
  }, [refresh])

  // useEffect(() => {
  //   console.log("refresh: ", refresh)
  //   if (window.location.pathname === "/checkout") {
  //     if (window.location.pathname === "/checkout") {
  //       setJustCheckout(true)
  //     } else {
  //       setJustCheckout(false)
  //     }
  //     if (
  //       window.location.pathname.includes("/app") &&
  //       !window.location.pathname.includes("/app/download")
  //     ) {
  //       setLocation(true)
  //     } else {
  //       setLocation(false)
  //     }
  //     console.log(
  //       (window.location.pathname.includes("checkout") &&
  //         window.location.pathname.includes("lp")) ||
  //         window.location.pathname.includes("checkout")
  //     )
  //     if (
  //       (window.location.pathname.includes("checkout") &&
  //         window.location.pathname.includes("lp")) ||
  //       window.location.pathname.includes("checkout")
  //     ) {
  //       console.log("Na checkoutu")
  //       setCheckout(true)
  //     }
  //   }
  // }, [refresh, window])
  // url, window?.location?.pathname,
  function setNav(state) {
    setShowNav(state)
  }

  function resolveLp(pathname) {
    const lpIndex = pathname.indexOf("lp")
    if (lpIndex !== -1) {
      const lpSubstring = pathname.substring(lpIndex)
      const regex = /lp(\d+)/
      const match = lpSubstring.match(regex)
      if (match !== null) {
        return match[0]
      }
    }
    return null
  }

  return (
    <HeaderComponent>
      {showInfo && isLoggedIn && location && <InformUser />}
      {showInfo && isLoggedIn && location && <PseudoModal />}
      <PositionContainer>
        <LogoBox>
          <a
            href="https://user-images.githubusercontent.com/76839446/158473684-0443da7e-e47d-43ad-a846-2ba382623f02.png"
            className="for-seo"
          >
            <StaticImage
              src="../../../images/PDF-LOGO.png"
              quality={60}
              alt="PDF Logo - SEO"
              className="for-seo"
            />
          </a>
          {pathname.includes("password-change") && (
            <Link to="/" onClick={closeMenu}>
              <Logo className="logo desktop" />
            </Link>
          )}

          {isLoadingApp &&
            pathname.includes("app") &&
            !pathname.includes("password-change") && (
              <Link to="/" onClick={closeMenu}>
                <Logo className="logo desktop" />
              </Link>
            )}
          {pathname.includes("app") && (
            <Link to="/" onClick={closeMenu}>
              <Logo className="logo mobile" />
            </Link>
          )}
          {!pathname.includes("app") && !pathname.includes("password-change") && (
            <Link
              to={resolveLp(pathname) ? "/" + resolveLp(pathname) : "/"}
              onClick={closeMenu}
            >
              <Logo className="logo" />
            </Link>
          )}
        </LogoBox>
        <MidSection>{/* <ToolsDropdown /> */}</MidSection>

        {!isLoggedIn && !checkout && (
          <NotLoggedInNavbar
            showNav={showNav}
            setShowNav={setNav}
            pathname={pathname}
            closeMenu={closeMenu}
            justCheckout={justCheckout}
          />
        )}
        {isLoggedIn && (
          <LoggedInNavbar
            showNav={showNav}
            setShowNav={setNav}
            pathname={pathname}
            closeMenu={closeMenu}
            justCheckout={justCheckout}
          />
        )}
        {checkout && <CountryDropdown />}
      </PositionContainer>
    </HeaderComponent>
  )
}

export default NewHeader

import React from "react"
import styled from "styled-components"
import Logo from "../../icons/PDF-logo-vector.svg"
import { Link } from "gatsby"

//Error boundary has to be class component - still can't achieve that with functional components
//Later you can add custom UI

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  .logo-icon {
    transform: scale(2);
    margin-bottom: 48px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Content>
            <Link to="/">
              <Logo className="logo-icon" />
            </Link>
            <h1>Ooops! Something went wrong.</h1>
            <p>
              Contact{" "}
              <a href="https://support.pdfpro.com/hc/en-us/requests/new">
                support
              </a>{" "}
              so we can get the error fixed.
            </p>
            <p>You may also try to refresh the page or try again later.</p>
          </Content>
        </Container>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary

import styled from "styled-components"

export const Navbar = styled.nav`
  display: flex;
  align-items: center;
  height: 100%;

  li {
    list-style: none;
    margin: 0px;
    padding: 0px;
    @media (max-width: 1000px) {
      margin-left: 5%;
      &:nth-child(-n + 3) {
        transform: translateY(-64px);
      }
      &:nth-child(4) {
        position: fixed;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
      }
    }
    @media (max-height: 500px) {
      &:nth-child(-n + 3) {
        transform: translateY(-36px);
      }
    }

    a {
      height: 100%;
      width: 100%;
      font-family: Work Sans;
      font-style: normal;
      font-weight: normal;
      text-decoration: none;
      font-size: 18px;
      line-height: 27px;
      color: #222222;
      flex: none;
      order: 0;
      flex-grow: 0;
      box-sizing: border-box;

      @media (max-width: 1000px) {
        font-size: 20px;
        line-height: 27px;
        text-transform: uppercase;
      }

      &:hover {
        color: gray;
      }
    }

    /* fix for width change on hover */
    a::before {
      display: block;
      content: attr(title);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }
`

export const Ul = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 40px;
  text-decoration: none;
  margin: 0px;
  @media (max-width: 1000px) {
    display: ${({ show }) => (show ? "flex" : "none")};
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: fixed;
    background: #ffffff;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: -1;
    padding: 0px;
  }
`

import React, { useEffect, useRef } from "react"
import Icon from "../../icons/tools/ToolsIcon.svg"
import Arrow from "../../icons/tools/ArrowDown.svg"
import styled from "styled-components"
import { useState } from "react"
import { Link, navigate } from "gatsby"
import Merge from "../../icons/tools/header/MergeIcon.svg"
import Rotate from "../../icons/tools/header/RotateIcon.svg"
import Extract from "../../icons/tools/header/ExtractIcon.svg"
import Delete from "../../icons/tools/header/DeleteIcon.svg"
import Rearrange from "../../icons/tools/header/RearrangeIcon.svg"
import Split from "../../icons/tools/header/SplitIcon.svg"
import Image from "../../icons/tools/header/ImageIcon.svg"
import Word from "../../icons/tools/header/WordIcon.svg"
import Excel from "../../icons/tools/header/ExcelIcon.svg"
import PPT from "../../icons/tools/header/PPTIcon.svg"
import Html from "../../icons/tools/header/HtmlIcon.svg"
import Protect from "../../icons/tools/header/ProtectIcon.svg"
import ArrowRight from "../../icons/tools/header/ArrowRight.svg"
import { StaticImage } from "gatsby-plugin-image"

const ToolsBox = styled.div`
  height: 42px;
  width: max-content;
  /* padding: 8px;
  padding-left: 16px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #425466;
  .arrow {
    margin: 8px;
    :before {
      content: "test";
    }
  }

  :hover {
    .holder {
      background: #f5f6fc;
    }
    color: #6361ff;
    svg {
      color: #6361ff;
      /* stroke: #6361ff; */
    }
    path {
      /* fill: #6361ff; */
      fill: #6361ff;
    }
    .arrow {
      path {
        fill: none;

        stroke: #6361ff;
      }
    }
  }

  ${props =>
    props.open &&
    `
    .holder {
        background: #f5f6fc;
    }
    color: #6361ff;
    svg {
      color: #6361ff;
      /* stroke: #6361ff; */
    }
    path {
      /* fill: #6361ff; */
      fill: #6361ff;
    }
    .arrow {
      path {
        fill: none;

        stroke: #6361ff;
      }
    }
  `}

  span {
    @media (max-width: 675px) {
      display: none;
    }

    @media (min-width: 1000px) {
      font-weight: 400;
      font-size: 15px;
      color: #333333;
    }
  }
`
const InnerHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 8px;
  padding-left: 16px;
  cursor: pointer;
`

const Holder = styled.div`
  display: flex;
  width: 90vw;

  max-width: 772px;
  min-height: 388px;
  position: absolute;
  top: 76px;
  transform: translateX(-780px);
  background: white;
  padding: 32px;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 17px 35px -7px rgba(50, 50, 93, 0.07),
    0px 10px 20px -10px rgba(0, 0, 0, 0.07);
  gap: 20px;

  @media (max-width: 1330px) {
    /* left: 5vw; */
    transform: translateX(calc(-40vw - 8px));

    width: 40vw;
    flex-wrap: wrap;
  }
`

const ProductHolder = styled(Holder)`
  display: flex;
  flex-direction: column;
  transform: translateX(0);

  gap: 0;
  left: auto;
  max-width: 440px;
  min-height: 232px;
  padding: 16px;
  .gatsby-image-wrapper {
    min-width: 48px;
    min-height: 48px;
    width: 48px;
    height: 48px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  ${props => !props.open && `display: none;`}
`

const H2 = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #333333;
  margin-bottom: 8px;
  margin-left: 8px;
`

const ToolLink = styled(Link)`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #425466;
  display: flex;
  align-items: center;

  text-decoration: none;
  padding: 8px;
  padding-left: 4px;

  svg {
    margin-right: 8px;
  }
  .arrow {
    opacity: 0;
    margin: 0;
    margin-left: auto;
    path {
      stroke: #333333;
    }
  }

  :hover {
    background: #f5f6fc;
    .arrow {
      opacity: 1;
      path {
        stroke: #333333;
      }
    }
  }
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 12px; */
  min-width: max-content;
  width: 161px;
`

const ColumnProduct = styled(Column)`
  width: auto;
`
const ProductHolderInner = styled.div`
  padding: 12px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-height: 96px;
  height: 96px;
  gap: 24px;
  cursor: pointer;
  :hover {
    background: #f5f6fc;
  }
`

const ProductTitle = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #333333;
  margin-bottom: 8px;
`

const ProductDescription = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #333333;
  width: 296px;
`

function ToolsDropdown() {
  const [open, setOpen] = useState(false)
  const [openTools, setOpenTools] = useState(false)

  //useRef
  const ref = useRef(null)
  const refTools = useRef(null)

  let timeoutId

  useEffect(() => {
    ref.current.addEventListener("mouseover", e => {
      setOpen(true)
      clearTimeout(timeoutId)
    })
    refTools.current.addEventListener("mouseover", e => {
      setOpenTools(true)
      clearTimeout(timeoutId)
    })
    ref.current.addEventListener("mouseout", e => {
      timeoutId = setTimeout(function () {
        setOpen(false)
        setOpenTools(false)
      }, 500)
    })
  }, [])

  function Close() {
    setOpen(!open)
    if (open) setOpenTools(false)
  }

  return (
    <ToolsBox
      ref={ref}
      open={open}
      onClick={() => {
        Close()
      }}
    >
      <InnerHolder className="holder">
        {/* <Icon />  */}
        <span> Products</span> <Arrow className="arrow" />
      </InnerHolder>
      {/* {open && ( */}
      <ProductHolder open={open}>
        <ProductHolderInner
          onClick={() => {
            navigate("/checkout/")
            Close()
          }}
        >
          <StaticImage
            src="../../images/header/logo.png"
            alt="logo"
            width={48}
            height={48}
          />
          <ColumnProduct>
            <ProductTitle>PDF Pro Windows App &rarr;</ProductTitle>
            <ProductDescription>
              Working with PDF files has never been easier and fast.
            </ProductDescription>
          </ColumnProduct>
        </ProductHolderInner>
        <ProductHolderInner
          ref={refTools}
          onClick={() => {
            navigate("/pdf-online-tools/")
            Close()
          }}
        >
          <StaticImage
            src="../../images/header/logo.png"
            alt="logo"
            width={48}
            height={48}
          />
          <ColumnProduct>
            <ProductTitle>Online Tools &rarr;</ProductTitle>
            <ProductDescription>
              Working with PDF files online.
            </ProductDescription>
          </ColumnProduct>
        </ProductHolderInner>
      </ProductHolder>
      {/* )} */}
      {openTools && (
        <Holder>
          <Column>
            <H2>View & Edit</H2>
            <ToolLink to="/merge-pdf">
              <Merge /> Merge PDF <ArrowRight className="arrow" />
            </ToolLink>
            <ToolLink to="/rotate-pdf">
              <Rotate /> Rotate PDF <ArrowRight className="arrow" />
            </ToolLink>
            <ToolLink to="/extract-pdf-pages">
              <Extract /> Extract PDF <ArrowRight className="arrow" />
            </ToolLink>
            <ToolLink to="/delete-pdf-pages">
              <Delete /> Delete PDF <ArrowRight className="arrow" />
            </ToolLink>
            {/* <ToolLink to="/rearrange-pdf">
              <Rearrange /> Rearrange PDF <ArrowRight className="arrow" />
            </ToolLink> */}
            <ToolLink to="/split-pdf">
              <Split /> Split PDF <ArrowRight className="arrow" />
            </ToolLink>
          </Column>

          <Column>
            <H2>Convert from PDF</H2>
            <ToolLink to="/pdf-to-png">
              <Image /> PDF to PNG <ArrowRight className="arrow" />
            </ToolLink>
            <ToolLink to="/pdf-to-jpg">
              <Image /> PDF to JPG <ArrowRight className="arrow" />
            </ToolLink>
            <ToolLink to="/pdf-to-tiff">
              <Image /> PDF to TIFF <ArrowRight className="arrow" />
            </ToolLink>
            <ToolLink to="/pdf-to-bmp">
              <Image /> PDF to BMP <ArrowRight className="arrow" />
            </ToolLink>
            {/* <ToolLink to="/pdf-to-word">
              <Word /> PDF to Word <ArrowRight className="arrow" />
            </ToolLink> */}
            <ToolLink to="/pdf-to-excel">
              <Excel /> PDF to Excel <ArrowRight className="arrow" />
            </ToolLink>
            {/* <ToolLink to="/pdf-to-ppt">
              <PPT /> PDF to PTT <ArrowRight className="arrow" />
            </ToolLink>
            <ToolLink to="/pdf-to-html">
              <Html /> PDF to HTML <ArrowRight className="arrow" />
            </ToolLink> */}
          </Column>

          <Column>
            <H2>Convert to PDF</H2>
            <ToolLink to="/png-to-pdf">
              <Image /> PNG to PDF <ArrowRight className="arrow" />
            </ToolLink>
            <ToolLink to="/jpg-to-pdf">
              <Image /> JPG to PDF <ArrowRight className="arrow" />
            </ToolLink>
            <ToolLink to="/tiff-to-pdf">
              <Image /> TIFF to PDF <ArrowRight className="arrow" />
            </ToolLink>
            <ToolLink to="/bmp-to-pdf">
              <Image /> BMP to PDF <ArrowRight className="arrow" />
            </ToolLink>
          </Column>

          <Column>
            <H2>Security</H2>
            <ToolLink to="/password-protect-pdf">
              <Protect /> Protect PDF <ArrowRight className="arrow" />
            </ToolLink>
          </Column>
        </Holder>
      )}
    </ToolsBox>
  )
}

export default ToolsDropdown

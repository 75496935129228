import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import Flag from "../shared/Flag"
import { countryInfo } from "../helpers/PriceCalculator"
import DropdownLocation from "../shared/DropdownLocation"
import { countryTagList } from "../helpers/CountryTags"
import Down from "../../icons/chevron-down.svg"

const options = Object.entries(countryTagList).map(([value, label]) => ({
  value,
  label,
}))

const MainStyle = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  cursor: pointer;

  svg {
    min-width: 24px;
    min-height: 24px;
  }
`

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  max-width: 0;
  overflow: visible;
  position: relative;
  right: 15%;
  @media (max-width: 920px) {
    right: auto;

    max-width: max-content;
  }
`
const CountryText = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  color: rgba(0, 0, 0, 0.6);
  margin-left: 12px;
  min-width: max-content;
  width: max-content;
  margin-right: 16px;
  @media (max-width: 640px) {
    display: none;
  }
`

function CountryDropdown() {
  const [isOpen, setIsOpen] = useState(false)
  let info = countryInfo()
  if (info?.countryTag == "US") {
    info.countryName = "United States"
  }

  const holder = useRef(null)

  return (
    <MainWrapper>
      <MainStyle onClick={() => setIsOpen(!isOpen)} ref={holder}>
        <Flag tag={info?.countryTag} />
        <CountryText>{info?.countryName}</CountryText>
        <Down />
      </MainStyle>
      {isOpen && (
        <DropdownLocation
          options={options}
          setOpen={setIsOpen}
          width={holder.current.offsetWidth}
        />
      )}
    </MainWrapper>
  )
}

export default CountryDropdown

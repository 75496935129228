import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GlobalAuthContext } from "../Contexts/GlobalAuthContext"
import { useContext, useEffect, useState } from "react"
import "./layout.css"
import { createGlobalStyle } from "styled-components"
import CookieConsent, { Cookies } from "react-cookie-consent"
import styled from "styled-components"
import NewHeader from "./shared/header/newHeader"
import Contacts from "../components/contacts"
import ContactsTools from "./contactsTools"
import ContactsLp from "./landing-pages/contactsLp"
import { globalHistory } from "@reach/router"
import ToolFooter from "./tools/ToolFooter"
import NewFooter from "./shared/NewFooter"
import PasswordPrompt from "./tools/PasswordPrompt"
import MacPopup from "./shared/macPopup"

const GlobalStyle = createGlobalStyle`
html,body{
  width:100%;
  height:auto;
  overflow-y:${({ scroll }) =>
    scroll === "show" ? "hidden" : scroll === true ? "hidden" : "visble"};
    
}
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden; ;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
`
const PseudoHeader = styled.div`
  width: 100%;
  height: 70px;
`

const Footer = styled.footer`
  background-color: #222222;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0rem;
  width: 100%;

  .footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1440px;
    width: 90%;

    @media (max-width: 768px) {
      width: 95%;
    }
  }
`

const Layout = ({
  children,
  wrapLocation,
  onLp,
  onCheckout,
  onTool,
  location,
}) => {
  //logic that allows us to use window object - using basic logic without hooks
  let showFooter
  let otherPages
  const [isReady, setIsReady] = useState(false)

  //global context to know whether user is logged in
  const { scroll } = useContext(GlobalAuthContext)

  //Function that checks parameter and pushes it to data layer
  function checkForParam() {
    //grabbing query string from URL
    const queryString = window.location.search
    //parsing query string parameters
    const urlParams = new URLSearchParams(queryString)
    //returning the first value associated to given parameter
    const userId = urlParams.get("userId")
    // console.log(userId)
    if (userId !== null) {
      //GTM pushing to Data Layer
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "login",
        userId: `${userId}`,
      })
    }
  }

  const [Lp, setLp] = useState(onLp)
  const [specialLP, setSpecialLP] = useState(false)
  /// Detects URL change
  const [loc, setLoc] = useState(null)
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH" || action == "REPLACE" || action == "POP")
        setLoc(loc => (loc = !loc))
    })
  }, [setLoc])

  useEffect(() => {
    if (window.location.pathname.includes("/lp3")) {
      setSpecialLP(true)
    }

    if (
      (window.location.pathname.includes("/lp") ||
        window.location.pathname.includes("/rm")) &&
      !window.location.pathname.includes("/lp3/checkout") &&
      !window.location.pathname.includes("/lp6/checkout")
    ) {
      console.log("onlp", Lp)
      setLp(true)
      Cookies.set("consent", true)
    } else {
      console.log("not onlp", Lp)

      setLp(false)
    }
    console.log("onlp", Lp)
  }, [loc])

  //Fires function on first render
  useEffect(() => {
    checkForParam()
    setTimeout(() => {
      setIsReady(true)
    }, 5000)
  }, [])

  // link source tracking - FastSpring
  useEffect(() => {
    try {
      if (window.location.search != null) {
        var cookie = ""
        var params = [
          "source",
          "utm_source",
          "utm_medium",
          "utm_content",
          "utm_campaign",
          "utm_term",
        ]
        for (var i = 0; i < params.length; i++) {
          var srcPos = window.location.search.indexOf(params[i])
          if (srcPos != -1) {
            var srcStr = window.location.search.substr(
              srcPos + params[i].length + 1
            )
            var srcAmpPos = srcStr.indexOf("&")
            var srcVal =
              srcAmpPos != -1 ? srcStr.substring(0, srcAmpPos) : srcStr
            var now = new Date()
            now.setDate(now.getDate() + 1)
            document.cookie =
              params[i] +
              "=" +
              escape(srcVal) +
              ";" +
              "expires=" +
              now.toGMTString() +
              "; path=/;"
          }
        }
      }

      if (document.cookie != null) {
        var cookiearray = document.cookie.split(";")
        var append = ""
        for (var i = 0; i < cookiearray.length; i++) {
          var name = cookiearray[i].split("=")[0]
          while (name.charAt(0) == " ") name = name.substring(1, name.length)
          var value = unescape(cookiearray[i].split("=")[1])
          if (name == "source" || name == "campaign")
            append =
              append + (append.length > 0 ? "&" : "") + name + "=" + value
        }
        if (append.length > 0) {
          var links = document.getElementsByTagName("a")
          for (var i = 0; i < links.length; i++) {
            var dom = links[i]
            if (dom.href.indexOf("sites.fastspring.com") != -1) {
              var ch = dom.href.indexOf("?") != -1 ? "&" : "?"
              dom.href = dom.href + ch + append
            }
          }
          var forms = document.getElementsByTagName("form")
          for (var i = 0; i < forms.length; i++) {
            var dom = forms[i]
            if (dom.action.indexOf("sites.fastspring.com") != -1) {
              var ch = dom.action.indexOf("?") != -1 ? "&" : "?"
              dom.action = dom.action + ch + append
            }
          }
        }
      }
    } catch (e) {}
  }, [])

  //just for blog
  if (
    typeof window !== "undefined" &&
    window.location.pathname.includes("/blog")
  ) {
    otherPages = true
  } else {
    otherPages = false
  }

  //special layouts for different pathnames
  if (typeof window !== "undefined") {
    if (
      window.location.pathname.includes("/login") ||
      window.location.pathname.includes("/password-reset") ||
      window.location.pathname.includes("/register") ||
      window.location.pathname.includes("/404/") ||
      window.location.pathname.includes("/purchased") ||
      window.location.pathname.includes("/app") ||
      window.location.pathname.includes("/download") ||
      window.location.pathname.includes("-pdf") ||
      window.location.pathname.includes("pdf-")
    ) {
      showFooter = false
    } else {
      showFooter = true
    }
  }

  console.log("onCheckout", onCheckout)
  return (
    <Container>
      {/* {Lp && <HeaderLp wrapLocation={wrapLocation} />} */}
      {/* Only for /checkout */}
      <MacPopup location={location} />
      {!Lp && onCheckout && <NewHeader />}
      {specialLP && onCheckout && <PseudoHeader />}

      {/* {!Lp && onCheckout && <PseudoHeader />} */}
      {/* for every other case */}
      {!Lp && !onCheckout && <NewHeader />}
      {!Lp && !onCheckout && <PseudoHeader />}
      <GlobalStyle scroll={scroll} />
      <PasswordPrompt name={"pswrt"} />
      {isReady && (
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="consent"
          onAccept={() => {
            // Cookies.set("consent", true)
            //GTM pushing to Data Layer
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: "userConsent",
              consent: true,
            })
            window.dataLayer.push({
              event: "gatsby-route-change",
            })
          }}
          contentStyle={{
            display: `flex`,
            justifyContent: `center`,
            fontSize: `16px`,
            maxWidth: `50rem`,
            paddingRight: `0`,
            marginRight: `0`,
            color: `#222`,
          }}
          buttonStyle={{
            backgroundColor: `#00a651`,
            color: `white`,
            marginLeft: `24px`,
            padding: `8px 25px`,
            borderRadius: `5px`,
          }}
          style={{
            display: `flex`,
            justifyContent: `center`,
            backgroundColor: `#f0f0f0FC`,
            zIndex: `9999`,
          }}
        >
          <span>
            This site uses{" "}
            <Link to="/cookies" style={{ color: `#222` }}>
              technical
            </Link>{" "}
            as well as{" "}
            <Link to="/cookies" style={{ color: `#222` }}>
              profiling
            </Link>{" "}
            cookies. Learn more by visiting our{" "}
            <Link to="/cookies" style={{ color: `#222` }}>
              Cookie Policy
            </Link>{" "}
            and our{" "}
            <Link to="/privacy" style={{ color: `#222` }}>
              Privacy Policy
            </Link>{" "}
          </span>
        </CookieConsent>
      )}
      <Content>{children}</Content>

      {otherPages && !Lp && <NewFooter wrapLocation={wrapLocation} />}

      {/* {onTool && <ToolFooter wrapLocation={wrapLocation} />} */}
      {onTool && <NewFooter wrapLocation={wrapLocation} />}
      {!onLp && onCheckout && <NewFooter wrapLocation={wrapLocation} />}

      {Lp && !onCheckout && <ContactsLp wrapLocation={wrapLocation} />}
      {onLp && onCheckout && <ContactsLp wrapLocation={wrapLocation} />}

      {showFooter && Lp && (
        <Footer>
          <div className="footer-container">
            <p
              style={{
                fontStyle: `normal`,
                fontWeight: `normal`,
                fontSize: `12px`,
                lineHeight: `15px`,
                textAlign: `center`,
                color: `#DCDCDC`,
                height: `fit-content`,
                margin: `0.5rem 0`,
              }}
            >
              PDF Pro is a desktop software for Windows distributed by PDF Pro
              Software Inc. The software "PDF Pro" and the company "PDF Pro
              Software Inc." are in no way affiliated with Adobe. The PDF file
              format is an open file format published under ISO 32000-1:2008
            </p>
            <p
              style={{
                fontStyle: `normal`,
                fontWeight: `normal`,
                fontSize: `12px`,
                lineHeight: `2px`,
                textalign: `center`,
                color: `#DCDCDC`,
                height: `fit-content`,
                margin: `0.5rem 0`,
              }}
            >
              © 2017-{new Date().getFullYear()}, PDF Pro Software Inc. All
              rights reserved.
            </p>
          </div>
        </Footer>
      )}
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

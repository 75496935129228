import React, { useEffect, useState } from "react"
import styled from "styled-components"

const FlagStyle = styled.img`
  max-width: 48px;
  max-height: 32px;
  min-height: 32px;

  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Loading = styled.div`
  min-width: 43px;
  max-height: 32px;
  min-height: 32px;
  background: #f2f2f2;
`
function Flag({ tag }) {
  const [icon, setIcon] = useState(null)

  useEffect(() => {
    async function importIcon() {
      const iconModule = await import(`../../images/flags/${tag}.svg`)
      setIcon(iconModule.default)
    }

    importIcon()
  }, [tag])

  return <div>{icon ? <FlagStyle src={icon} alt={tag} /> : <Loading />}</div>
}

export default Flag

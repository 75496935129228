import React, { createContext, useEffect, useState } from "react"

// helper functions
import {
  customEventCheckoutStepOne,
  customEventCheckoutStepTwo,
} from "../components/helpers/Analytics"

export const CheckoutNewContext = createContext({})

const CheckoutNewContextProvider = ({ children }) => {
  const [itemSelected, setItemSelected] = useState(false)
  const [isChecked, setIsChecked] = useState(true)
  //initial number of users
  const [initialNumber, setInitialNumber] = useState(1)
  const [product, setProduct] = useState()
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [includeTax, setIncludeTax] = useState(true)

  useEffect(() => {
    if (!itemSelected) {
      customEventCheckoutStepOne()
    } else if (itemSelected) {
      customEventCheckoutStepTwo()
    }
  }, [itemSelected])

  return (
    <CheckoutNewContext.Provider
      value={{
        itemSelected,
        setItemSelected,
        isChecked,
        setIsChecked,
        initialNumber,
        setInitialNumber,
        product,
        setProduct,
        email,
        setEmail,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        includeTax,
        setIncludeTax,
      }}
    >
      {children}
    </CheckoutNewContext.Provider>
  )
}

export default CheckoutNewContextProvider

import React from "react"
import { useEffect, useState } from "react"
import styled from "styled-components"
import Close from "../../icons/close-icon-popup.svg"
import { navigate } from "gatsby"
import { Link } from "gatsby"
import AppleIcon from "../../icons/popup/apple.svg"

const MacPopupStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  backdrop-filter: blur(8px);
  display: none;
  justify-content: center;
  align-items: center;
`

const Holder = styled.div`
  width: 90%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  padding: 32px;
  gap: 16px;
  box-shadow: 0px 50px 100px -20px rgba(50, 50, 93, 0.25),
    0px 30px 60px -30px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  svg {
    margin-left: auto;
    cursor: pointer;
    max-width: 12px;
    max-height: 12px;
    color: #333333;
  }
`

const InnerHolder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  padding: 32px;
  gap: 16px;
  @media (max-width: 480px) {
    padding: 0;
  }
`

const Title = styled.div`
  font-weight: 600;
  font-size: 25px;
  line-height: 33px;
  letter-spacing: -0.03em;
  color: #333333;
  margin-bottom: 8px;
`

const Row = styled.div`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.03em;

  a {
    display: inline-block;
    color: #6361ff;
  }
`

const PartnerButton = styled.button`
  all: unset;
  background: #00ca42;
  border-radius: 30px;
  padding: 14px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;
  cursor: pointer;
  @media (max-width: 880px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    font-size: 16px;
    line-height: 16px;
  }
  @media (max-width: 380px) {
    padding: 10px 12px;

    font-size: 14px;
    line-height: 14px;
  }
`

const ContinueButton = styled.button`
  all: unset;
  border: 1px solid #00ca42;
  border-radius: 30px;
  padding: 14px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  letter-spacing: -0.03em;
  color: #00ca42;
  cursor: pointer;
  @media (max-width: 880px) {
    width: 100%;
  }
  @media (max-width: 480px) {
    font-size: 16px;
    line-height: 16px;
  }
  @media (max-width: 380px) {
    padding: 10px 12px;

    font-size: 14px;
    line-height: 14px;
  }
`

const ButtonRow = styled(Row)`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  gap: 20px;
`

const IconHolder = styled.div`
  width: 100px;
  height: 100px;
  margin-bottom: 24px;
  svg {
    min-width: 100%;
    min-height: 100%;
  }
`
function MacPopup({ location }) {
  const [open, setIsOpen] = useState(false)
  const [type, setType] = useState("")
  const [text, setText] = useState("")
  const [link, setLink] = useState("")

  useEffect(() => {
    var isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0
    var isiPhone = /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

    // Usage
    if (isMac) {
      setType("Mac")
      setLink("https://readdle.8kpa2n.net/c/4477090/763057/11051")
    }

    if (isiPhone) {
      console.log("User is on an iPhone")
      setType("iPhone")
      setLink("https://readdle.8kpa2n.net/c/4477090/763057/11051")
    }

    if (isMac || isiPhone) {
      const popup = document.getElementById("MacPopup")
      if (popup) {
        if (!location.pathname.includes("checkout")) {
          setText("Otherwise, please check out our free online PDF Tools")
          popup.style.display = "none"
        } else {
          setText("")
          popup.style.display = "flex"
        }
      }
    }
  }, [location])
  return (
    <MacPopupStyle id="MacPopup">
      <Holder>
        <Close
          onClick={() =>
            (document.getElementById("MacPopup").style.display = "none")
          }
        />
        <InnerHolder>
          <IconHolder>
            <AppleIcon />
          </IconHolder>

          <Title>Welcome {type} User!</Title>
          <Row>
            Welcome {type} User!{" "}
            <b>
              {" "}
              Unfortunately, PDF Pro isn’t compatible with Mac at this time.
            </b>{" "}
            However, for your benefit, we’ve partnered a great PDF editor for
            Mac, from a company we trust. You’ll be in good hands.
          </Row>
          <ButtonRow>
            <PartnerButton onClick={() => navigate(link)}>
              Visit Mac Partner page{" "}
            </PartnerButton>

            <ContinueButton
              onClick={() =>
                (document.getElementById("MacPopup").style.display = "none")
              }
            >
              {text !== ""
                ? "Remain on the pdfpro.com website"
                : "Continue to PDF Pro Checkout"}
            </ContinueButton>
          </ButtonRow>
        </InnerHolder>
      </Holder>
    </MacPopupStyle>
  )
}

export default MacPopup

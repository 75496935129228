import React from "react"
import styled from "styled-components"
import Main from "./styles/mainStyle"
import MainWrapper from "./styles/mainWrapperStyle"
import { BuyButton } from "./styles/buttonStyle"
import { navigate } from "gatsby"
import { useEffect, useState } from "react"
import Window from "../../icons/window.svg"

const CtaMain = styled(Main)`
  background: #e9eaec;
`

const CtaWrapper = styled(MainWrapper)`
  flex-direction: column;
  max-width: 1440px;

  margin-top: 32px;
  margin-bottom: 32px;

  @media (max-width: 475px) {
    flex-wrap: wrap;
  }
`

const Title = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 50px;
  line-height: 50px;
  color: #36454f;

  @media (max-width: 900px) {
    flex-basis: 100%;
    text-align: center;
  }

  @media (min-width: 900px) {
    width: 100%;
  }
`

const Button = styled(BuyButton)`
  margin-top: 0;
  @media (max-width: 900px) {
    margin: auto;
    margin-top: 20px;
  }

  height: 75px;
  width: 315px;
  max-width: 315px;
  border-radius: 8px;
  box-shadow: none;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
`
const ButtonHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left; /* width: 100%; */
  div {
    margin-top: 8px;
    font-weight: 400;
    font-size: 20px;
    color: #36454f;
  }
  @media (max-width: 900px) {
    align-items: center;
  }
  @media (max-width: 900px) {
    width: 100%;
  }
`
const ButtonUnderText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #4e526b;
  margin-top: 8px;
  font-family: Work Sans;
  text-align: right;
  color: black;

  @media (max-width: 475px) {
    text-align: center;
  }
`

const MainTitle = styled.h2`
  font-size: 25px;
  text-align: center;
  margin-bottom: 28px;
  font-weight: 1100;
  color: #000000;
`

const Bottom = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`
function BlogCta({ location, openPopup }) {
  const [lpType, setLpType] = useState("")

  useEffect(() => {
    if (window.location.href.includes("lp1")) setLpType("lp1")
    if (window.location.href.includes("lp2")) setLpType("lp2")
    if (window.location.href.includes("lp3")) setLpType("lp3")
    if (window.location.href.includes("lp6")) setLpType("lp6")
  }, [])
  return (
    <CtaMain>
      <CtaWrapper>
        {/* <MainTitle>Try PDF Pro free for 15 days </MainTitle> */}
        <Bottom>
          <ButtonHolder>
            <Title>Discover PDF PRO</Title>
            <div>Working with PDF documents has never been easier</div>
          </ButtonHolder>

          <Button onClick={() => openPopup()}>
            DOWNLOAD NOW
            <Window />
          </Button>
        </Bottom>
      </CtaWrapper>
    </CtaMain>
  )
}

export default BlogCta

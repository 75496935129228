// utilities
import { FastSpringPriceFiltered } from "../../utils/FastSpringPricesFiltered"
import { FastSpringPrice } from "../../utils/FastSpringPrices"
import { getCountry } from "./Geolocator"

export function filterFastSpringPricesObject() {
  // function that turns array to the objects so you can more easily and faster access certain values - you can have this on backend to get back production ready object
  const obj = {}
  FastSpringPrice.products.forEach(elem => {
    obj[elem.product] = elem
  })
  return obj
}

export function countryInfo() {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  let countryTag = getCountry(timezone)?.countryTag
  let countryName = getCountry(timezone)?.countryName

  let product = "pdf-one-year"
  let filteredProduct = FastSpringPriceFiltered[product]?.pricing[countryTag]

  return {
    countryTag: countryTag,
    countryName: countryName,
    currency: filteredProduct?.currency,
  }
}
export function determineLocalCurrencyBasedOnAllowedCurrencies(productPath) {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  let countryTag = getCountry(timezone)?.countryTag

  let filteredProduct = FastSpringPriceFiltered[productPath].pricing[countryTag]

  if (filteredProduct?.currency?.toLowerCase() === "USD".toLowerCase()) {
    return "$"
  } else if (filteredProduct?.currency?.toLowerCase() === "AUD".toLowerCase()) {
    return "$"
  } else if (filteredProduct?.currency?.toLowerCase() === "CAD".toLowerCase()) {
    return "$"
  } else if (filteredProduct?.currency?.toLowerCase() === "EUR".toLowerCase()) {
    return "€"
  } else if (filteredProduct?.currency?.toLowerCase() === "GBP".toLowerCase()) {
    return "£"
  }
}

export function determineFullPath(productPath, isChecked, initialNumber) {
  if (isChecked && initialNumber > 1) {
    return productPath + "-multi-ocr"
  } else if (isChecked && initialNumber === 1) {
    return productPath + "-ocr"
  } else if (!isChecked && initialNumber > 1) {
    return productPath + "-multi"
  } else if (!isChecked && initialNumber === 1) {
    return productPath
  }
}

export function totalPrice(productPath) {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  let countryTag = getCountry(timezone)?.countryTag
  let total
  if (productPath.includes("pdf-monthly")) {
    total = {
      totalPriceString:
        FastSpringPriceFiltered[productPath]?.pricing[countryTag]?.display,
      totalPriceInteger:
        FastSpringPriceFiltered[productPath]?.pricing[countryTag]?.price,
    }
    return total
  } else if (!productPath.includes("perpetual")) {
    total = {
      totalPriceString:
        FastSpringPriceFiltered[productPath]?.pricing[countryTag]
          ?.quantityDiscount["1"]?.unitPriceDisplay,
      totalPriceInteger:
        FastSpringPriceFiltered[productPath]?.pricing[countryTag]
          ?.quantityDiscount["1"].unitPrice,
    }
    return total
  } else if (productPath.includes("pdf-perpetual")) {
    total = {
      totalPriceString: FastSpringPriceFiltered[productPath]?.pricing[
        countryTag
      ]?.price.toString(),
      totalPriceInteger:
        FastSpringPriceFiltered[productPath]?.pricing[countryTag]?.price,
    }
    return total
  }
}

export function productPricingInformation(productPath) {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  let countryTag = getCountry(timezone)?.countryTag
  let total
  if (productPath.includes("pdf-monthly")) {
    total = {
      pricePerMonth: FastSpringPriceFiltered[productPath]?.pricing[
        countryTag
      ]?.price.toString(),
      pricePerMonthInteger:
        FastSpringPriceFiltered[productPath]?.pricing[countryTag]?.price,
    }
    return total
  } else if (productPath.includes("pdf-two")) {
    let monthlyPricePerUser = (
      FastSpringPriceFiltered[productPath]?.pricing[countryTag]?.price / 24
    ).toFixed(2)

    let discountMonthylPricePerUser = (
      FastSpringPriceFiltered[productPath]?.pricing[countryTag]
        ?.quantityDiscount["1"].unitPrice / 24
    ).toFixed(2)

    total = {
      totalPrice: FastSpringPriceFiltered[productPath]?.pricing[
        countryTag
      ]?.price.toString(),
      discountTotalPrice: FastSpringPriceFiltered[productPath]?.pricing[
        countryTag
      ]?.quantityDiscount["1"]?.unitPrice.toString(),
      pricePerMonth: monthlyPricePerUser,
      discountPricePerMonth: discountMonthylPricePerUser,
      totalPriceInteger:
        FastSpringPriceFiltered[productPath]?.pricing[countryTag]?.price,
      discountTotalPriceInteger:
        FastSpringPriceFiltered[productPath]?.pricing[countryTag]
          ?.quantityDiscount["1"]?.unitPrice,
    }
    return total
  } else if (productPath.includes("pdf-one")) {
    let monthlyPricePerUser = (
      FastSpringPriceFiltered[productPath]?.pricing[countryTag]?.price / 12
    ).toFixed(2)

    let discountMonthylPricePerUser = (
      FastSpringPriceFiltered[productPath]?.pricing[countryTag]
        ?.quantityDiscount["1"].unitPrice / 12
    ).toFixed(2)

    total = {
      totalPrice: FastSpringPriceFiltered[productPath]?.pricing[
        countryTag
      ]?.price.toString(),
      discountTotalPrice: FastSpringPriceFiltered[productPath]?.pricing[
        countryTag
      ]?.quantityDiscount["1"].unitPrice.toString(),
      pricePerMonth: monthlyPricePerUser.toString(),
      discountPricePerMonth: discountMonthylPricePerUser.toString(),
      totalPriceInteger:
        FastSpringPriceFiltered[productPath]?.pricing[countryTag]?.price,
      discountTotalPriceInteger:
        FastSpringPriceFiltered[productPath]?.pricing[countryTag]
          ?.quantityDiscount["1"].unitPrice,
    }
    return total
  } else if (productPath.includes("pdf-perpetual")) {
    total = {
      totalPrice: FastSpringPriceFiltered[productPath]?.pricing[
        countryTag
      ]?.price.toString(),
      discountTotalPrice: FastSpringPriceFiltered[productPath]?.pricing[
        countryTag
      ]?.quantityDiscount["1"]?.unitPrice?.toString(),
      totalPriceInteger:
        FastSpringPriceFiltered[productPath]?.pricing[countryTag]?.price,
      discountTotalPriceInteger:
        FastSpringPriceFiltered[productPath]?.pricing[countryTag]
          ?.quantityDiscount["1"]?.unitPrice,
    }
    return total
  }
}

export function calculateOcr(totalPrice, productPrice, productPath) {
  let discountOcrPrice = totalPrice - parseInt(productPrice)
  let ocrPrice = discountOcrPrice * 2
  console.log("discountOcrPrice", discountOcrPrice)
  if (productPath.includes("pdf-two")) {
    let ocrPricePerMonth = discountOcrPrice / 24
    return {
      discountOcrPrice: discountOcrPrice,
      ocrPrice: ocrPrice,
      ocrPricePerMonth: ocrPricePerMonth,
    }
  } else if (productPath.includes("pdf-one")) {
    let ocrPricePerMonth = discountOcrPrice / 12
    return {
      discountOcrPrice: discountOcrPrice,
      ocrPrice: ocrPrice,
      ocrPricePerMonth: ocrPricePerMonth,
    }
  } else if (productPath.includes("pdf-perpetual")) {
    return {
      discountOcrPrice: discountOcrPrice,
      ocrPrice: ocrPrice,
    }
  } else if (productPath.includes("pdf-monthly")) {
    return {
      monthlyPrice: discountOcrPrice,
    }
  }
}

export function calculateOcrUpdate(productPath) {
  let realPath = productPath + "-ocr"
  let totalPriceVar = totalPrice(realPath).totalPriceInteger
  let productPrice = totalPrice(productPath).totalPriceInteger

  let ocrPrice = totalPriceVar - parseInt(productPrice)
  let discountOcrPrice = ocrPrice / 2
  console.log("discountOcrPrice", discountOcrPrice)
  if (productPath.includes("pdf-two")) {
    let ocrPricePerMonth = discountOcrPrice / 24
    return {
      discountOcrPrice: discountOcrPrice,
      ocrPrice: ocrPrice,
      ocrPricePerMonth: ocrPricePerMonth,
    }
  } else if (productPath.includes("pdf-one")) {
    let ocrPricePerMonth = discountOcrPrice / 12
    return {
      discountOcrPrice: discountOcrPrice,
      ocrPrice: ocrPrice,
      ocrPricePerMonth: ocrPricePerMonth,
    }
  } else if (productPath.includes("pdf-perpetual")) {
    return {
      discountOcrPrice: discountOcrPrice,
      ocrPrice: ocrPrice,
    }
  } else if (productPath.includes("pdf-monthly")) {
    return {
      monthlyPrice: discountOcrPrice,
    }
  }
}

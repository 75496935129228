import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"
import Close from "../../../icons/close-icon-popup.svg"
import HamburgerMenu from "../../../icons/hamburger_menu.svg"
import { BuyButton } from "../header/shared/buyButton.style"
import { Navbar, Ul } from "./shared/navbarStyles.style"
import { Cookies } from "react-cookie-consent"
import ToolsDropdown from "../ToolsDropdown"

const NotLoggedIn = styled.div`
  .desktop-navbar {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .mobile-navbar {
    display: none;
    @media (max-width: 1000px) {
      display: flex;
    }
  }

  .different {
    @media (max-width: 1000px) {
      margin-top: -65px;
    }
  }

  .special {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 2px;
    left: -3rem;
    cursor: pointer;
    border: none;
    background: transparent;

    @media (max-width: 1124px) {
      left: 0rem;
    }

    @media (max-width: 869px) {
      left: -3rem;
    }

    @media (max-width: 768px) {
      left: 1.3rem;
      top: -1.5rem;
    }

    :hover {
      color: gray;
    }
  }

  .hamburger-menu {
    display: none;
    height: 16px;
    width: 22px;
    cursor: pointer;
    /* this is old value */
    /* margin-right: 26px; */
    /* better design */
    margin-right: 0px;
    z-index: 10;

    @media (max-width: 1000px) {
      display: block;
    }
  }

  .close-icon {
    display: none;
    height: 18px;
    width: 18px;
    cursor: pointer;
    /* this is old value */
    /* margin-right: 26px; */
    /* better design */
    margin-right: 0px;
    @media (max-width: 1000px) {
      display: block;
    }
  }
`

const OnlyCheckout = styled.div`
  display: ${({ special }) => (special ? "flex" : "none")};
  width: max-content;
  height: 28px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #222222;
  margin: 0px 12px;
  position: relative;
  left: -4rem;

  .links {
    width: max-content;
    height: 28px;
    left: 272px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: #007de6;
    margin: 0px 12px;
  }
`

function NotLoggedInNavbar({
  pathname,
  setShowNav,
  showNav,
  justCheckout,
  closeMenu,
}) {
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    setIsLoading(false)
  }, [])

  if (isLoading) {
    return null
  }

  function setFlag() {
    Cookies.set("checkout", true)
  }
  return (
    <NotLoggedIn>
      <Navbar className="desktop-navbar">
        {!showNav && (
          <HamburgerMenu
            className="hamburger-menu"
            onClick={() => {
              setShowNav(true)
            }}
          />
        )}
        {showNav && (
          <Close
            className="close-icon"
            onClick={() => {
              setShowNav(false)
            }}
          />
        )}
        <Ul show={showNav}>
          {!pathname.includes("login") && !pathname.includes("checkout") && (
            <li>
              <Link to="/features" title="Features" onClick={closeMenu}>
                Features
              </Link>
            </li>
          )}
          {!pathname.includes("login") && !pathname.includes("checkout") && (
            <li>
              <a
                title="Support"
                href="https://support.pdfpro.com/hc/en-us/requests/new"
                rel="noreferrer noopener"
                target="_blank"
                onClick={closeMenu}
              >
                Support
              </a>
            </li>
          )}
          {!pathname.includes("login") && !pathname.includes("checkout") && (
            <li>
              <Link to="/blog" title="Blog" onClick={closeMenu}>
                Blog
              </Link>
            </li>
          )}
          {/* <ToolsDropdown /> */}
          {!pathname.includes("login") && !pathname.includes("checkout") && (
            <li className="different">
              <BuyButton
                className="start"
                onClick={() => {
                  navigate("/checkout")
                  closeMenu()
                }}
              >
                Pricing
              </BuyButton>
            </li>
          )}
          {!pathname.includes("login") && !pathname.includes("checkout") && (
            <li className="different">
              <Link to="/login" title="Sign In" onClick={closeMenu}>
                Sign In
              </Link>
            </li>
          )}
          {pathname.includes("login") && (
            <li>
              <button
                className="special"
                onClick={() => {
                  navigate("/register")
                }}
              >
                Register
              </button>
            </li>
          )}
          {pathname.includes("checkout") && (
            <li>
              <OnlyCheckout special={justCheckout} onClick={setFlag}>
                Already have a PDF Pro account?{" "}
                <Link to="/login" className="links">
                  Log in
                </Link>
              </OnlyCheckout>
            </li>
          )}
        </Ul>
      </Navbar>
      <Navbar className="mobile-navbar">
        {!showNav && (
          <HamburgerMenu
            className="hamburger-menu"
            onClick={() => {
              setShowNav(true)
            }}
          />
        )}
        {showNav && (
          <Close
            className="close-icon"
            onClick={() => {
              setShowNav(false)
            }}
          />
        )}
        <Ul show={showNav}>
          <li>
            <Link to="/features" title="Features" onClick={closeMenu}>
              Features
            </Link>
          </li>
          <li>
            <a
              title="Support"
              href="https://support.pdfpro.com/hc/en-us/requests/new"
              rel="noreferrer noopener"
              target="_blank"
              onClick={closeMenu}
            >
              Support
            </a>
          </li>
          <li>
            <Link to="/blog" title="Blog" onClick={closeMenu}>
              Blog
            </Link>
          </li>
          <li className="different">
            <BuyButton
              className="start"
              onClick={() => {
                navigate("/checkout")
                closeMenu()
              }}
            >
              Pricing
            </BuyButton>
          </li>
          <li className="different">
            <Link to="/login" title="Sign In" onClick={closeMenu}>
              Sign In
            </Link>
          </li>
        </Ul>
      </Navbar>
    </NotLoggedIn>
  )
}

export default NotLoggedInNavbar

import React, { useEffect } from "react"
import styled from "styled-components"
import Close from "../../icons/close-icon-popup.svg"
import { useState } from "react"
import api from "../../components/api"
import { Link, navigate } from "gatsby"
import Email from "../../icons/email-popup.svg"
import Loader from "../../components/shared/loader"
import { useForm } from "react-hook-form"
import { Cookies } from "react-cookie-consent"

const ExtraP = styled.p`
  display: initial;
`
const ExtraBtn = styled.button`
  display: initial;
`

const Style = styled.section`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100%;

  @media (max-height: 851px) {
    height: 100vh;
  }

  background: rgba(34, 34, 34, 0.6);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  .under-title-download {
    width: 400px;
    height: 81px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #222222;
    margin-top: -12px;
    @media (max-width: 675px) {
      width: 278px;
      height: fit-content;
      font-size: 12px;
      line-height: 18px;
      margin-top: -24px;
    }
  }

  .popup-container {
    width: 600px;
    height: 414px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
    @media (max-width: 675px) {
      width: 334px;
      height: 481px;
    }
    @media (max-width: 340px) {
      width: 315px;
    }

    .close-button {
      height: 12px;
      width: 12px;
      position: absolute;
      top: 25px;
      right: 45px;
      cursor: pointer;
    }

    .white-container {
      height: 80%;
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: 675px) {
        height: 85%;
      }

      .message-container {
        width: 504px;
        height: max-content;
        @media (max-width: 675px) {
          width: 300px;
        }
      }

      h2.title {
        width: 399px;
        height: max-content;
        font-family: Work Sans;
        font-style: normal;
        font-weight: 300;
        font-size: 36px;
        line-height: 36px;
        text-align: center;
        color: #111111;
        margin-top: 36px;

        strong {
          font-weight: 500;
        }
        @media (max-width: 675px) {
          width: 180px;
          font-size: 32px;
          line-height: 36px;
        }
      }

      .under-title-text {
        width: 400px;
        height: 81px;
        font-family: Work Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #222222;
        @media (max-width: 675px) {
          width: 278px;
          height: 96px;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .form-container {
        width: 504px;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 675px) {
          flex-direction: column;
          height: 120px;
          width: 300px;
          margin-top: ${({ existss }) => (existss === false ? `0px` : `-12px`)};
        }

        .email-input {
          width: 350px;
          height: 60px;
          background: #ffffff;
          border: 1px solid #999999;
          box-sizing: border-box;
          border-radius: 100px;
          text-indent: 24px;

          &::placeholder {
            width: 273px;
            height: 24px;
            font-family: Work Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 21px;
            color: #999999;
          }
          @media (max-width: 675px) {
            height: 50px;
            width: 300px;
          }
        }
        .form-button {
          width: 144px;
          height: 60px;
          background: #00ca42;
          border-radius: 60px;
          border: none;
          font-family: Work Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 27px;
          color: #ffffff;
          cursor: pointer;

          &:hover {
            background: rgba(0, 202, 66, 0.8);
          }
          @media (max-width: 675px) {
            height: 50px;
            width: 300px;
            font-size: 18px;
            line-height: 27px;
            margin-top: ${({ existss }) =>
              existss === false ? `0px` : `12px`};
          }
        }
      }
    }
    .red-container {
      width: 100%;
      height: 20%;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background-image: linear-gradient(
        to right,
        #bf1b09,
        #c82714,
        #d2311e,
        #db3a28,
        #e44331
      );
      @media (max-width: 675px) {
        height: 15%;
      }
    }
  }
`

const SuccessMsg = styled.div`
  height: 80%;
  width: 100%;
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 675px) {
    height: 85%;
  }

  h2.title {
    width: 399px;
    height: 36px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 36px;
    text-align: center;
    color: #111111;
    margin-top: 46px;
    @media (max-width: 675px) {
      width: 180px;
      height: 72px;
      font-size: 32px;
      line-height: 36px;
      margin-bottom: 0px;
    }
  }
  .under-title-text {
    width: 400px;
    height: fit-content;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #222222;

    @media (max-width: 675px) {
      width: 278px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .icon {
    height: 60px;
    width: 60px;
  }
`

const WrongEmail = styled.p`
  font-family: Work Sans;
  width: 273px;
  height: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #e44331;
  margin: 0;
  padding: 0;
  align-self: flex-start;
  margin-top: -24px;
  margin-left: 24px;
  @media (max-width: 675px) {
    width: 273px;
    overflow: hidden;
  }
`

const AlreadyInUse = styled.p`
  font-family: Work Sans;
  width: 373px;
  height: 48px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #e44331;
  margin: 0;
  padding: 0;
  margin-bottom: 24px;
  align-self: flex-start;
  margin-top: -24px;
  margin-left: 24px;

  @media (max-width: 675px) {
    width: 273px;
    margin-bottom: 36px;
  }

  .link {
    all: revert;
    text-decoration: none;
    color: blue;
    &:hover {
      color: #0e86ee;
    }
  }
`

function TryPdf(props) {
  //manage email
  const [email, setEmail] = useState("")

  //state that manages if button is disabled
  const [isDisabled, setIsDisabled] = useState(false)

  //loader state
  const [isLoading, setIsLoading] = useState(false)

  //error messages
  const [failEmailValidation, setFailEmailValidation] = useState(false)
  const [failAlreadyExists, setFailAlreadyExists] = useState(false)
  //success message
  const [success, setSuccess] = useState(false)

  //submit popup function
  function onSubmit(data) {
    const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (data.Email !== null && regexEmail.test(data.Email)) {
      setIsDisabled(true)
      setIsLoading(true)
      const encoded = encodeURIComponent(data.Email)
      api
        .get(
          `/api/v1/pdfpro/email-on-product/?email=${encoded}|pdfpro&product_code=pdf`
        )
        .then(res => {
          // console.log(res)
          if (
            res.data.licenses_on_product_count.any === 0 &&
            res.data.licenses_on_product_count.trial < 2
          ) {
            api
              .post("/api/v1/pdfpro/invite-user/", {
                email: data.Email,
                // product_code: "pdf",
                company_code: "pdfpro",
              })
              .then(res => {
                setFailEmailValidation(false)
                setFailAlreadyExists(false)
                setIsDisabled(false)
                setTimeout(delayedDownload, 1500)
                Cookies.set("source_trial", "true", { expires: 60 })
                setTimeout(props.close, 15000)
                setIsLoading(false)
                setSuccess(true)
                // this is for universal analytics - we will delete this after 01/07/2023
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: "buttonClick",
                  category: "Enter Email on pdfpro.com/register",
                  action: "Click",
                  label: "Email address entered on pdfpro.com/register",
                })
                // GA4 Event
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: "TrialEmailReceived",
                  category: "TrialEmailReceived",
                  action: "Try PDF Pro - Trial email delivered",
                  label: "Try PDF Pro - Trial email delivered",
                })

                // GA4 Event - staging
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: "GA4 - Custom Event",
                  eventName: "Deliver Trial Invite Email",
                  action: "Trial invite email is delivered to the recepient",
                })
              })
              .catch(err => {
                console.log(err)
                setIsLoading(false)
                setIsDisabled(false)
              })
          } else {
            setIsLoading(false)
            setIsDisabled(false)
            setFailEmailValidation(false)
            setFailAlreadyExists(true)
            setError("EmailExists", {
              type: "EmailExists",
              message: (
                <AlreadyInUse>
                  There is already an account associated with this email
                  address. Login{" "}
                  <Link className="link" to="/login">
                    here
                  </Link>{" "}
                  or reset password{" "}
                  <Link className="link" to="/password-reset">
                    here
                  </Link>
                  .
                </AlreadyInUse>
              ),
            })
          }
        })
        .catch(err => {
          console.log(err)
          setIsLoading(false)
          setIsDisabled(false)
        })
    } else {
      setIsLoading(false)
      setIsDisabled(false)
      setFailAlreadyExists(false)
      setFailEmailValidation(true)
      setError("IncorrectEmail", {
        type: "IncorrectEmail",
        message: <WrongEmail>Please enter a valid email address</WrongEmail>,
      })
    }
  }

  // after user gets trial it will start download automatically
  function delayedDownload() {
    navigate(
      "https://1543d3a591e926011c7a-9b1b76dc96f52415d7c2bfd8c3a40309.ssl.cf2.rackcdn.com/PDFProInstaller.exe"
    )
  }

  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    var isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0
    var isiPhone = /iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    const popup = document.getElementById("MacPopup")
    window.disableMac = window?.disableMac ?? false
    if (popup && window?.disableMac === false)
      if (isMac || isiPhone) {
        popup.style.display = "flex"
        props.close()
      }

    function HandleSroll() {
      window.scrollTo({
        top: 70,
        behavior: "instant",
      })
    }

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    if (
      isMobile &&
      (window.location.href.includes("-pdf") ||
        window.location.href.includes("pdf-"))
    ) {
      HandleSroll()

      window.intervalId = setInterval(() => {
        HandleSroll()
      }, 1)
    }

    return () => {
      if (window.intervalId) clearInterval(window.intervalId)
    }
  }, [])

  return (
    <Style className="follow-scroll">
      <div className="popup-container">
        <Close onClick={props.close} className="close-button" />
        {isLoading === true && <Loader />}
        {success === false && isLoading === false && (
          <form onSubmit={handleSubmit(onSubmit)} className="white-container">
            <h2 className="title">
              Try <strong>PDF Pro</strong> for 15 days!
            </h2>
            <p className="under-title-text">
              Register now to get a free demo copy of PDF Pro. You will receive
              download and activation instructions by email.
            </p>
            <ExtraP existss={failAlreadyExists} className="form-container">
              <input
                type="email"
                name="imale"
                placeholder="Email Address"
                className="email-input"
                {...register("Email", {
                  required: "Email is required",
                  onChange: () => {
                    clearErrors("IncorrectEmail")
                    clearErrors("EmailExists")
                  },
                })}
              />
              <ExtraBtn
                disabled={isDisabled}
                existss={failAlreadyExists}
                className="form-button"
                onClick={() => {
                  // GA4 Event
                  window.dataLayer = window.dataLayer || []
                  window.dataLayer.push({
                    event: "EmailValidated",
                    category: "EmailValidated",
                    action: "Try PDF Pro - Email validated",
                    label: "Try PDF Pro - Email validated",
                  })

                  // GA4 Event - staging
                  window.dataLayer = window.dataLayer || []
                  window.dataLayer.push({
                    event: "GA4 - Custom Event",
                    eventName: "Email validation/Client-side validation",
                    action:
                      "User entered email address, email address is validated",
                  })
                }}
              >
                Get Trial
              </ExtraBtn>
            </ExtraP>
            <div className="message-container">
              {Object.values(errors).map(prop => {
                return <>{prop.message}</>
              })}
            </div>
          </form>
        )}
        {success === true && isLoading === false && (
          <SuccessMsg>
            <h2 className="title">Thank you!</h2>
            <p>
              <Email className="icon" />
            </p>
            <p className="under-title-text">
              Please check your email for download and login instructions
            </p>
            <p className="under-title-download">
              Download will start in a few seconds.
            </p>
          </SuccessMsg>
        )}
        <div className="red-container"></div>
      </div>
    </Style>
  )
}

export default TryPdf

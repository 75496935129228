export function customEventCheckoutStepOne() {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "buttonClick",
    category: "Reached checkout 1.step",
    action: "Reached Step 1",
    label: "Reached Choose Plan",
  })

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "Checkout Process",
    category: "Checkout Process",
    action: "Reached Step 1 checkout",
    label: "Reached Step 1 checkout",
  })
}

export function customEventCheckoutStepTwo() {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "buttonClick",
    category: "Reached checkout 2.step",
    action: "Reached Step 2",
    label: "Reached Payment - enter payment info",
  })
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "Checkout Process",
    category: "Checkout Process",
    action: "Reached Step 2 checkout",
    label: "Reached Step 2 checkout",
  })
}

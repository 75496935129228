export function formatDate() {
  if (typeof window !== "undefined" ? localStorage.getItem("orderID") : false) {
    const options = { year: "numeric", month: "long", day: "numeric" }
    let date = new Date(
      JSON.parse(localStorage.getItem("orderID")).created_at
    ).toLocaleDateString("en-US", options)
    return date
  }
}

export function formatDateEnd() {
  if (typeof window !== "undefined" ? localStorage.getItem("orderID") : false) {
    const options = { year: "numeric", month: "long", day: "numeric" }
    let date = new Date(
      JSON.parse(localStorage.getItem("orderID")).validity_period
    ).toLocaleDateString("en-US", options)
    return date
  }
}

export function timeLeft() {
  if (typeof window !== "undefined" ? localStorage.getItem("orderID") : false) {
    let currentDate = new Date()
    let date = new Date(
      JSON.parse(localStorage.getItem("orderID")).validity_period
    )
    let result = Math.ceil(Math.abs(date - currentDate) / (1000 * 60 * 60 * 24))
    if (result === 1) {
      return `(${result} day remaining)`
    } else {
      return `(${result} days remaining)`
    }
  }
}

export function checkStatus() {
  if (typeof window !== "undefined" ? localStorage.getItem("orderID") : false) {
    let order = JSON.parse(localStorage.getItem("orderID"))
    if (order.is_trial === true) {
      let currentDate = new Date()
      let date = new Date(trialDuration())
      let result = Math.ceil((date - currentDate) / (1000 * 60 * 60 * 24))

      return result >= 0 ? true : false
    } else if (order.is_trial === false) {
      let currentDate = new Date()
      let date = new Date(
        JSON.parse(localStorage.getItem("orderID")).validity_period
      )
      let result = Math.ceil(
        Math.abs(date - currentDate) / (1000 * 60 * 60 * 24)
      )

      return result >= 0 ? true : false
    }
  }
}

export function trialDuration() {
  if (typeof window !== "undefined" ? localStorage.getItem("orderID") : false) {
    let order = JSON.parse(localStorage.getItem("orderID"))
    const options = { year: "numeric", month: "long", day: "numeric" }
    let date = new Date(JSON.parse(localStorage.getItem("orderID")).created_at)
    date.setDate(date.getDate() + parseInt(order.trial_days))
    let endOfTrial = date.toLocaleDateString("en-US", options)
    return endOfTrial
  }
}
export function trialLeft() {
  if (typeof window !== "undefined" ? localStorage.getItem("orderID") : false) {
    let currentDate = new Date()
    let date = new Date(trialDuration())
    let result = Math.ceil((date - currentDate) / (1000 * 60 * 60 * 24))
    // console.log("wtf is happening", result)
    if (result === 1) {
      return `(${result} day remaining)`
    } else {
      return `(${result} days remaining)`
    }
  }
}
export function isTrial() {
  if (typeof window !== "undefined" ? localStorage.getItem("orderID") : false) {
    let order = JSON.parse(localStorage.getItem("orderID"))
    if (order.is_trial) {
      return true
    } else {
      return false
    }
  }
}

export function billing() {
  if (typeof window !== "undefined" ? localStorage.getItem("orderID") : false) {
    let result
    let currentDate = new Date(
      JSON.parse(localStorage.getItem("orderID")).created_at
    )
    let date = new Date(
      JSON.parse(localStorage.getItem("orderID")).validity_period
    )
    if (JSON.parse(localStorage.getItem("orderID")).is_trial === true) {
      result = 15
    } else {
      result = Math.ceil(Math.abs(date - currentDate) / (1000 * 60 * 60 * 24))
    }
    if (result > 370) {
      return "Every Two Years"
    } else if (result > 360 && result < 370) {
      return "Every Year"
    } else if (result < 360 && result > 25) {
      return "Monthly"
    } else {
      return "Trial"
    }
  }
}

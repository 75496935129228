import React, { useEffect } from "react"
import { SocialIcon } from "react-social-icons"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share"

import styled from "styled-components"
import Fb from "../../icons/blog/Facebook-sm.svg"
import Em from "../../icons/blog/Email-sm.svg"
import Li from "../../icons/blog/Linkedin-sm.svg"
import Tw from "../../icons/blog/Twitter-sm.svg"
import Wh from "../../icons/blog/Whapp-sm.svg"

const ShareStyle = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: flex-end;
  width: 120px;
  top: 200px;
  right: 0;
  justify-content: center;
  .react-share__ShareButton {
    margin: 2% 8px;
  }

  @media (max-width: 475px) {
    top: 150px;
    right: -3px;
  }

  @media (max-width: 800px) {
    top: 150px;
    right: -6px;
  }

  @media (max-width: 800px) {
    .react-share__ShareButton {
      margin: 0 8px;
      transform: scale(0.8);
    }
  }

  @media (max-width: 480px) {
    .react-share__ShareButton {
      margin: 0;
      transform: scale(0.6);
    }
  }
  @media (max-width: 380px) {
    .react-share__ShareButton {
      margin: 0;
      transform: scale(0.6);
    }
    width: 80%;
    margin: auto;
  }
  z-index: 100;
`
const MediaBox = styled.div`
  background: #f3f3f3;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  height: 55px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  transition: all 1s;
  /* z-index: 100000; */

  .Fb {
    transform: translateX(-4px);
  }

  svg {
    position: fixed;
    transition: all 1s;

    right: 28px;
    @media (max-width: 475px) {
      right: 20px;
    }

    @media (max-width: 800px) {
      right: 20px;
    }
  }

  span {
    transition: all 1s;
    position: fixed;
    right: -24px;
    opacity: 0;
    color: white;

    margin-left: 16px;
  }

  :hover {
    padding-left: 24px;
    justify-content: left;
    width: 120px;
    background: #fd5b41;
    span {
      right: 24px;

      opacity: 1;
      color: white;
    }

    svg {
      right: 88px;
    }

    path {
      fill: white;
      fill-opacity: 1;
    }
  }
`

const ShareButtons = ({ url, title, description }) => {
  useEffect(() => {
    // let parent = document.querySelector(".sticky").parentElement
    // while (parent) {
    //   const hasOverflow = getComputedStyle(parent).overflow
    //   if (hasOverflow !== "visible") {
    //     console.log(hasOverflow, parent)
    //   }
    //   parent = parent.parentElement
    // }
  }, [])

  return (
    <ShareStyle className="sticky">
      <FacebookShareButton url={url} quote={description}>
        <MediaBox>
          <Fb className="Fb" /> <span>Share</span>
        </MediaBox>
      </FacebookShareButton>

      <LinkedinShareButton url={url} title={title} summary={description}>
        <MediaBox>
          <Li /> <span>Share</span>
        </MediaBox>
      </LinkedinShareButton>

      <TwitterShareButton url={url} title={description}>
        <MediaBox>
          <Tw /> <span>Share</span>
        </MediaBox>
      </TwitterShareButton>

      <WhatsappShareButton url={url} title={description}>
        <MediaBox>
          <Wh /> <span>Share</span>
        </MediaBox>
      </WhatsappShareButton>

      <EmailShareButton
        subject={title}
        body={`${description}, check full guide here: ${url}`}
      >
        <MediaBox>
          <Em /> <span>Share</span>
        </MediaBox>
      </EmailShareButton>
    </ShareStyle>
  )
}

export default ShareButtons

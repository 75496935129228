import React, { useEffect, useState, useContext } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import Seo from "../components/seo"
import AuthorCard from "../components/blog/authorCard"
import { Breadcrumbs } from "../components/blog/breadcrumbs"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import BlogCard from "../components/blog/blogCard"
// import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import ShareButtons from "../components/blog/sharebuttons"
import { GlobalAuthContext } from "../Contexts/GlobalAuthContext"
import TryPdf from "../components/shared/TryPdf"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import ContentTable from "../components/blog/contentTable"
import TimeIcon from "../icons/blog/time.svg"
import BlogCta from "../components/blog/cta"
import Recommendation from "../components/blog/recommendation"

const Blog = styled.div`
  display: flex;
  flex-direction: column;
  color: #111111;
  font-family: Work Sans;
  font-style: normal;

  .vector {
    width: 100px;
  }

  .body {
    a {
      cursor: pointer;
      color: #d5442d;
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }
  }

  div[data-oembed] {
    position: relative;
    padding-top: 56%;
    margin-bottom: 24px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (max-width: 475px) {
    }
  }

  .ctaButton {
    min-width: 200px;
    height: 60px;
    width: max-content;
    background: #ffffff;
    border: 2px solid #41c93f;
    border-radius: 80px;
    padding: 24px 48px;
    color: #41c93f !important;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
      text-decoration: none !important;
      background: #41c93f;
      color: white !important;
      cursor: pointer;
    }

    @media (max-width: 475px) {
      padding: 12px 24px;
      font-size: 16px;
      line-height: 19px;
    }
    margin-top: 32px;
  }
`
const Div = styled.div`
  text-align: left;
  margin-right: 5%;
  /* margin-left: auto; */
  margin: auto;
  width: 80%;
  max-width: 1440px;
  transition: width 1s;
  @media (max-width: 980px) {
    width: 90%;
  }
  @media (max-width: 375px) {
    width: 92%;
  }

  /* p {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  } */
`

const DivRelated = styled.div`
  text-align: left;
  margin: auto;
  width: 100%;
  max-width: 1440px;
`

const Hero = styled(GatsbyImage)`
  width: 100%;
  height: auto;
  padding: 1%;
  margin: 8px auto;
  img {
    max-height: 480px;
    max-width: 80%;
    width: auto;
    height: auto;
    margin: auto;
    object-fit: fill !important;
  }
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  //flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  width: 80%;
  margin: 0 auto;
  gap: 5% 5%;

  a {
    text-decoration: none;
  }
  /* align-items: center;
  justify-content: center; */

  @media (max-width: 1400px) {
    flex-wrap: wrap;
  }
`

const RelatedBlogPosts = styled.div`
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: #36454f;
  margin: 64px 0;
  text-transform: uppercase;

  margin-bottom: 32px;

  @media (max-width: 475px) {
    font-size: 24px;
    line-height: 34px;
  }
`

const Related = styled.div`
  background: rgba(236, 236, 238, 0.5);
  width: 100%;

  padding-bottom: 64px;
`

const PublishDate = styled.div`
  width: 100%;
  text-align: left;
  @media (max-width: 1040px) {
    margin-left: 0;
  }
`

const PublishAuthor = styled.div`
  width: 100%;
  text-align: left;
  a {
    color: #111111;
    text-decoration: none;
    font-weight: 800;
  }
  @media (max-width: 1040px) {
    margin-bottom: 8px;
    margin-left: 0;
  }
`

const CardPreview = styled.div`
  width: 90%;
  height: 1000px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation-name: breath-animation;
  animation-duration: 5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  margin: 32px auto;
  h2 {
    width: 100%;
    text-align: center;
  }

  @keyframes breath-animation {
    0% {
      width: 580px;
    }

    25% {
      width: 580px;
    }

    75% {
      width: 280px;
    }

    100% {
      width: 280px;
    }
  }
`

const SocialRow = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  align-items: center;
  margin-bottom: 64px;
  margin-top: 32px;
  @media (max-width: 1040px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .AuthorSection {
    display: flex;
    @media (max-width: 1040px) {
      flex-direction: column;
    }
    .DateAuthor {
      margin-left: 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      @media (max-width: 1040px) {
        margin-top: 32px;
        margin-left: 0px;
      }
    }
  }
`

const Title = styled.h1`
  width: 80%;
  margin: 32px auto;
  text-align: left;
  font-weight: 800;
  font-size: 40px;
  line-height: 47px;

  color: #36454f;
`

const Category = styled(Link)`
  color: #d5442d;
  width: 99px;
  height: 24px;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  text-align: center;
  border: 1px solid #d5442d;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  text-decoration: none;
  cursor: pointer;
  :hover {
    color: white;
    background: #d5442d;
  }
`
const CategoryDiv = styled(Div)`
  width: 100%;
  @media (max-width: 880px) {
    width: 90%;
  }
`
const Author = styled(GatsbyImage)`
  width: 75px;
  height: 75px;
  border-radius: 100%;
`

const Time = styled.div`
  background: #f0f0f0;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  svg {
    margin-right: 8px;
  }
  @media (max-width: 1040px) {
    margin-top: 32px;
  }
`

const Recommended = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1400px) {
    margin-top: 64px;
  }

  @media (max-width: 475px) {
    width: 90%;
  }
`
const RecTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #36454f;
  margin-top: 24px;
  max-width: 390px;
  text-align: left;
  width: 100%;
`

const Line = styled.div`
  height: 1px;
  background: #cdcdcd;
  width: 80%;
  margin: auto;
  margin-bottom: 64px;
  margin-top: 64px;
  @media (max-width: 980px) {
    width: 90%;
  }
`
const RecImage = styled(GatsbyImage)`
  width: 390px;
  height: 250px;
  background: white;
  img {
    object-fit: ${props => props.fit} !important;
  }

  @media (max-width: 475px) {
    width: 100%;
    height: 200px;
  }
`
const BlogPost = ({ data, location, pageContext }) => {
  // console.log(data)
  let { post } = data
  let { related1 } = data
  let { related2 } = data
  let { related3 } = data

  let { site } = data
  // console.log(post?.nodes[0].data)
  post = post?.nodes[0].data
  related1 = related1?.nodes[0]
  related2 = related2?.nodes[0]
  related3 = related3?.nodes[0]

  // console.log("related", related1)

  if (!post) post = data.prismicPdfproPost.data

  const url = site?.siteMetadata.siteUrl + location.pathname
  const description = post.description ? post.description : "Blog post"
  const { setScroll } = useContext(GlobalAuthContext)

  const [myPopUp, setMyPopUp] = useState("hide")
  const [tableContent, setTableContent] = useState()
  const [tableContentType, setTableContentType] = useState()

  const [test, setTest] = useState()

  //close popup function
  function closePopup() {
    setMyPopUp("hide")
    setScroll("hide")
  }
  //open popup function
  function openPopup(e) {
    setMyPopUp("show")
    setScroll("show")
  }

  function offsetAnchor() {
    let hash = decodeURI(window.location.hash.replace("#", ""))
    let target = document.getElementById(hash)

    if (target != null) {
      if (location.hash.length != 0) {
        window.scrollTo(window.scrollX, target.offsetTop - 100)
      }
    }
  }

  useEffect(() => {
    /// Popup logic
    const aElements = document.getElementsByTagName("a")
    const bEl = document.getElementById("#id")
    let content = []
    let contentType = []

    /// Handling scrolling difference on page landing,
    window.setTimeout(offsetAnchor, 1)

    for (var i = 0, l = aElements.length; i < l; i++) {
      let el = aElements[i]

      if (el.href.includes("popup")) {
        el.removeAttribute("href")

        el.addEventListener("click", () => {
          openPopup()
        })
      }

      if (el.href.includes(":NoFollow")) {
        el.href = el.href.split(":NoFollow")[0]
        el.rel = "nofollow"
      }

      if (el.href.includes(":CheckoutButton")) {
        el.href = el.href.split(":CheckoutButton")[0]
        el.classList.add("ctaButton")
      }

      if (
        el.href.toLocaleLowerCase().includes("TrialButton".toLocaleLowerCase())
      ) {
        el.removeAttribute("href")
        el.addEventListener("click", () => {
          openPopup()
        })

        el.classList.add("ctaButton")
      }
    }

    for (var i = 0, l = bEl.children.length; i < l; i++) {
      if (
        bEl.children[i].nodeName == "H2" ||
        bEl.children[i].nodeName == "H3"
      ) {
        bEl.children[i].id = bEl.children[i].textContent
        content.push(bEl.children[i].textContent)
        contentType.push(bEl.children[i].nodeName)
      }
    }

    setTableContent(t => content)
    setTableContentType(t => contentType)
  }, [])

  return (
    <>
      <Breadcrumbs
        currentPath={["/"].concat(
          location.pathname.split("/").filter(name => name !== "")
        )}
        customUrl={post?.title}
      />
      <Blog>
        <Seo
          title={post?.title}
          schemaMarkup={post?.schema}
          description={post?.description}
        />
        <Div>
          <CategoryDiv>
            <Div>
              <Category to={`/blog/${post?.category}`}>
                {post?.category}
              </Category>
            </Div>
          </CategoryDiv>

          {data?.prismicPdfproPost && (
            <CardPreview>
              <h2>Card Preview: </h2>
              <BlogCard
                post={{ data: post }}
                author={post.author.document.data.name}
                visible={"flex"}
                margin={"auto"}
              />
            </CardPreview>
          )}
          <Title>{post?.h1}</Title>

          <SocialRow>
            <div className="AuthorSection">
              <Author
                image={getImage(post.author.document.data.image)}
                alt="thumbnailA"
              />
              <div className="DateAuthor">
                <PublishAuthor>
                  <Link to={`/blog/${post.author.document?.data.name}`}>
                    {post.author.document?.data.name}
                  </Link>
                </PublishAuthor>
                <PublishDate>{post?.publish_date}</PublishDate>
              </div>
            </div>
            <Time>
              <TimeIcon /> <span>{post?.read_time}</span>
            </Time>
          </SocialRow>
          <ShareButtons
            url={url}
            title={post.title}
            description={description}
          />
          <Div
            className="body"
            dangerouslySetInnerHTML={{ __html: post?.introduction.html }}
          />
          <Hero image={getImage(post?.hero)} alt={"hero"} />
          {post?.teaser.html && (
            <Div
              className="body"
              dangerouslySetInnerHTML={{ __html: post?.teaser.html }}
            />
          )}
          <ContentTable content={tableContent} contentType={tableContentType} />

          <Div
            id="#id"
            className="body"
            dangerouslySetInnerHTML={{ __html: post?.body.html }}
          />
          {post?.faq.html && (
            <>
              <Line />
              <Div
                className="body"
                dangerouslySetInnerHTML={{ __html: post?.faq.html }}
              />
              <Line />
            </>
          )}
          {post?.recommendation.length > 1 && post?.recommendation && (
            <Recommendation content={post?.recommendation} />
          )}
          <ShareButtons
            url={url}
            title={post.title}
            description={description}
          />
        </Div>
      </Blog>
      {post?.author.document?.data.name && (
        <AuthorCard
          posts={post?.author}
          author={post?.author.document.data.name}
          bio={true}
        />
      )}
      <Related>
        <RelatedBlogPosts>YOU MIGHT ALSO LIKE </RelatedBlogPosts>

        <DivRelated>
          <Flex>
            {related1 && (
              <Recommended
                to={`/blog/${related1.data.category}/${
                  related1.data.url ? related1.data.url : related1.uid
                }`}
              >
                <RecImage
                  image={getImage(related1.data.thumbnail)}
                  alt="thumbnail"
                  fit={related1.data.fit_image}
                />
                <RecTitle>{related1.data.title}</RecTitle>
              </Recommended>
            )}
            {related2 && (
              <Recommended
                to={`/blog/${related2.data.category}/${
                  related2.data.url ? related2.data.url : related2.uid
                }`}
              >
                <RecImage
                  image={getImage(related2.data.thumbnail)}
                  alt="thumbnail"
                  fit={related2.data.fit_image}
                />
                <RecTitle>{related2.data.title}</RecTitle>
              </Recommended>
            )}

            {related3 && (
              <Recommended
                to={`/blog/${related3.data.category}/${
                  related3.data.url ? related3.data.url : related3.uid
                }`}
              >
                <RecImage
                  image={getImage(related3.data.thumbnail)}
                  alt="thumbnailA"
                  fit={related3.data.fit_image}
                />
                <RecTitle>{related3.data.title}</RecTitle>
              </Recommended>
            )}
          </Flex>
        </DivRelated>
      </Related>
      <BlogCta openPopup={openPopup} />
      {myPopUp === "show" && <TryPdf close={closePopup} />}
    </>
  )
}

export const query = graphql`
  query BlogQuery(
    $uid: String!
    $related1: String!
    $related2: String!
    $related3: String!
  ) {
    post: allPrismicPdfproPost(filter: { uid: { eq: $uid } }) {
      nodes {
        _previewable
        data {
          teaser {
            html
          }
          body {
            html
          }
          recommendation {
            link {
              html
            }
          }
          faq {
            html
          }
          introduction {
            html
          }
          h1
          schema
          title
          read_time
          fit_image
          category
          description
          publish_date(formatString: "MMMM D, Y")
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  facebook
                  image {
                    gatsbyImageData
                  }
                  linkedin
                  name
                  title
                  twitter
                  shortbio {
                    text
                  }
                }
              }
            }
          }
          hero {
            gatsbyImageData
          }
          title
        }
        uid
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }

    related1: allPrismicPdfproPost(filter: { uid: { eq: $related1 } }) {
      nodes {
        _previewable
        data {
          url
          fit_image
          title
          thumbnail {
            gatsbyImageData
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }

    related2: allPrismicPdfproPost(filter: { uid: { eq: $related2 } }) {
      nodes {
        _previewable
        data {
          url
          fit_image
          title
          thumbnail {
            gatsbyImageData
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }

    related3: allPrismicPdfproPost(filter: { uid: { eq: $related3 } }) {
      nodes {
        _previewable
        data {
          url
          fit_image
          title
          thumbnail {
            gatsbyImageData
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }
  }
`

export default withPrismicPreview(BlogPost)

import styled from "styled-components"

export const BuyButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 42px;
  background: #e44331;
  border-radius: 60px;
  margin: 0;
  padding: 0;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: red;
  }
`

import React, { useState } from "react"
import styled from "styled-components"
import Exit from "../../icons/tools/Close.svg"
import Question from "../../icons/tools/information_icon_question.svg"
import Eye from "../../icons/forms/eye.svg"
import EyeOff from "../../icons/forms/eyeOff.svg"

const PasswordPromptStyled = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0);
  backdrop-filter: blur(8px);
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  display: none;

  /* @media (max-width: 700px) {
    input {
      margin-left: 40px;
      :focus {
        margin-left: 40px;
      }
    }
    label {
      margin-left: 40px;
    }
  } */
`

const PasswordBox = styled.div`
  max-width: 445px;
  min-height: 256px;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 24px;
  /* padding-left: 46px; */
  @media (max-width: 420px) {
    padding-left: 5vw;
  }
  @media (max-width: 360px) {
    padding: 10px;
  }
  border: 1px dashed #6361ff;
  /* Drop Shadow */

  box-shadow: 0px 13px 27px -5px rgba(50, 50, 93, 0.25),
    0px 8px 16px -8px rgba(0, 0, 0, 0.3);
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .question {
    margin-right: 12px;
  }
`

const Row = styled.div`
  display: flex;
  max-width: 100%;
`

const RowExit = styled(Row)`
  width: 100%;
  min-height: 48px;
  .exit {
    margin-left: auto;
    margin-bottom: auto;
    cursor: pointer;
    @media (max-width: 360px) {
      margin-right: 16px;
      margin-top: 16px;
    }
  }
`

const Title = styled.div`
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 8px;
  width: 100%;
`
const Mes = styled.div`
  font-size: 15px;
  line-height: 24px;
  width: 100%;
  @media (max-width: 360px) {
    letter-spacing: -1px;
  }
  color: #333333;
`

const Input = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 24px;
  input {
    all: unset;
    margin-bottom: 24px;
    /* width: 100%; */
    max-width: 200px;
    min-width: 200px;
    height: 48px;
    background: #ffffff;
    border: 1px solid #e0e1e4;
    border-radius: 8px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 28px;
    :focus {
      all: unset;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 24px;
      max-width: 200px;
      min-width: 200px;
      height: 48px;

      background: #ffffff;
      border: 1px solid #6361ff;
      border-radius: 8px;
      padding: 0 20px;
      outline: 2px solid #badaef;
    }
  }

  svg {
    flex-basis: 0;
    max-width: 40px;
    max-height: 32px;
    min-width: 40px;
    min-height: 32px;
    margin-top: 10px;
    transform: translateX(-132px);
    :hover {
      cursor: pointer;
    }
  }
`
const Enter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #6361ff;
  border-radius: 6px;
  width: 85px;
  min-width: 85px;
  height: 40px;
  color: #ffffff;
  font-size: 15px;
  line-height: 24px;
  transform: translateX(-130px);
  margin-top: 5px;
  cursor: pointer;
`

function PasswordPrompt({ name }) {
  const [passVisible, setPassVisible] = useState(false)
  const [pass, setPass] = useState("")

  return (
    <PasswordPromptStyled id="PasswordPrompt">
      <PasswordBox>
        <RowExit>
          <Exit className="exit" onClick={() => window.location?.reload()} />
        </RowExit>
        <Row>
          <Column>
            {" "}
            <Question className="question" />{" "}
          </Column>
          <Column>
            <Title id="PasswordFileName">{name} </Title>
            <Mes>Enter password below to unlock PDF</Mes>
            <Input>
              <input
                id="PdfPasswordInput"
                onChange={e => {
                  setPass(e.target.value)
                  window.pdfPassword = e.target.value
                  console.log(e.target.value)
                }}
                value={pass}
                type={passVisible ? "text" : "password"}
              />{" "}
              {passVisible ? (
                <Eye onClick={() => setPassVisible(p => !p)} />
              ) : (
                <EyeOff onClick={() => setPassVisible(p => !p)} />
              )}
              <Enter id="EnterPassword" className="dynamically-added-element">
                Enter
              </Enter>
            </Input>
          </Column>
        </Row>
      </PasswordBox>
    </PasswordPromptStyled>
  )
}

export default PasswordPrompt

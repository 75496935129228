import React, { useContext } from "react"
import styled from "styled-components"
import { GlobalAuthContext } from "../../Contexts/GlobalAuthContext"
import Close from "../../icons/close-icon-inform.svg"

const ModalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  width: 100%;
  height: 42px;
  margin-left: 270px;
  @media (max-width: 1124px) {
    height: max-content;
    margin-left: 50px;
  }
`

const InfoText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-grow: 100%;
  height: 100%;
  width: 95%;
  color: white;
  font-weight: bold;
`
const CloseButtonHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14px;
  width: 14px;
  margin-left: 24px;
`
const CloseButton = styled(Close)`
  cursor: pointer;
  height: 14px;
  width: 14px;
`
const Holder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #33b5e5;
  position: fixed;
  top: 70px;
  z-index: -1;

  @media (max-width: 1330px) {
    padding: 8px 0px !important;
  }
`

function InformUser() {
  const { setShowInfo } = useContext(GlobalAuthContext)
  function close() {
    setShowInfo(false)
  }
  return (
    <Holder>
      <ModalContainer>
        <InfoText>
          Use the same email password to sign into the desktop application as
          you used to log in to your online account
          <CloseButtonHolder>
            <CloseButton onClick={close}>kgkgk</CloseButton>
          </CloseButtonHolder>
        </InfoText>
      </ModalContainer>
    </Holder>
  )
}

export default InformUser

import React, { useState, useContext } from "react"
import styled from "styled-components"
import Flag from "./Flag"
import { GlobalAuthContext } from "../../Contexts/GlobalAuthContext"
import { CheckoutNewContext } from "../../Contexts/CheckoutNewContext"
import Search from "../../icons/tools/search.svg"

const DropdownWrapper = styled.div`
  position: relative;
  overflow: visible;
  max-width: 0;
  z-index: 9999;
`

const DropdownList = styled.div`
  width: 100%;
  max-height: 325px;
  overflow-y: scroll;
  /* min-width: 176px; */
  padding: 0;
  margin: 0;
  background-color: #fff;
  padding-top: 48px;

  &::-webkit-scrollbar {
    width: 6px;
    background: rgba(0, 0, 0, 0);
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 5px;
    width: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    min-height: 80px;
    border-radius: 5px;
    /* box-shadow: 0 0 0 10px #d9d9d9; */

    width: 10px; /* wider than the track */
    margin-left: -1px;
  }
  .search {
    position: absolute;
    top: 24px;
    left: 24px;
  }
`

const DropdownListHolder = styled.div`
  min-width: 216px;

  position: absolute;
  top: 32px;
  ${props => props.width && `right: ${props.width}px;`}
  @media (max-width: 640px ) {
    ${props => props.widthMob && `right: ${props.widthMob}px;`}
  }
  /* right: -216px; */
  padding: 10px 12px;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  display: flex;
  /* flex-direction: column; */
  width: 100%;
`

const Line = styled.div`
  width: 2px;
  background: rgba(217, 217, 217, 0.5);
  height: 320px;
  border-radius: 5px;
  transform: translateX(-4px);
  z-index: 1;
  /* display: none; */
`

const DropdownListItem = styled.div`
  max-width: 166px;
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  color: rgba(0, 0, 0, 0.4);
  list-style: none;
  margin: 0;
  margin-top: 2px;
  overflow: hidden;

  cursor: pointer;
  /* &:hover {
    background-color: #f1f1f1;
  } */
  display: flex;
  align-items: center;
  gap: 9px;
  :hover {
    font-weight: 600;
    color: black;
  }
`

const LineHorz = styled.div`
  width: 92%;
  background: #e4e4e4;
  height: 1px;
  margin: 10px 0;
`
const Input = styled.input`
  all: unset;
  background: #ffffff;
  border: 1px solid #87929c;
  border-radius: 100px;
  box-sizing: border-box;
  /* padding: 8px; */
  width: 166px;
  height: 32px;
  margin-top: 16px;
  font-weight: 400;
  font-size: 13px;
  line-height: 205%;
  /* identical to box height, or 27px */
  padding-left: 32px;
  color: #666666;
  position: absolute;
  top: 0;
`

const DropdownLocation = ({ options, setOpen, width }) => {
  const { selectedOption, setSelectedOption } = useContext(GlobalAuthContext)

  const [searchFilter, setSearchFilter] = useState("")

  const { setItemSelected } = useContext(CheckoutNewContext)
  const handleOptionClick = option => {
    setSelectedOption(option)

    setOpen(false)
    window.myTag = option?.value
    console.log("myTag", window.myTag)
  }

  const importantOptions = ["US", "CA", "AU", "NZ", "GB", "ES", "FR", "DE"]

  const filteredOptions = importantOptions.reduce((acc, option) => {
    const found = options.find(o => o.value === option)
    if (found) {
      if (found.value === "US") {
        found.label = "United States"
      }
      if (found.value === "GB") {
        found.label = "United Kingdom"
      }

      acc.push(found)
    }
    return acc
  }, [])

  const restOptions =
    searchFilter.length == 0
      ? options.filter(option => !importantOptions.includes(option.value))
      : options.filter(option =>
          option.label.toLowerCase().includes(searchFilter.toLowerCase())
        )

  return (
    <DropdownWrapper>
      <DropdownListHolder
        width={-216 + (216 - width) / 2}
        widthMob={-216 + (216 - width)}
      >
        {/*  */}
        <DropdownList>
          <Input
            value={searchFilter}
            onChange={e => {
              setSearchFilter(e.target.value)
            }}
          />
          <Search className="search" />
          {searchFilter.length == 0 &&
            filteredOptions.map(option => (
              <DropdownListItem
                key={option.value}
                onClick={() => handleOptionClick(option)}
              >
                <Flag tag={option?.value} />
                {option.label}
              </DropdownListItem>
            ))}
          {searchFilter.length == 0 && <LineHorz />}
          {restOptions.map(option => (
            <DropdownListItem
              key={option.value}
              onClick={() => handleOptionClick(option)}
            >
              <Flag tag={option?.value} />
              {option.label}
            </DropdownListItem>
          ))}
        </DropdownList>
        <Line />
      </DropdownListHolder>
    </DropdownWrapper>
  )
}

export default DropdownLocation

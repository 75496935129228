import React from "react"
import styled from "styled-components"
import { useEffect, useState } from "react"
// const Table = styled.div`
//   display: flex;
//   flex-direction: column;

//   position: fixed;

//   top: 100px;
//   z-index: 99999;
//   width: 22%;
//   margin: 0 2%;
// `

// const Content = styled.a`
//   border-top-right-radius: 8px;
//   border-bottom-right-radius: 8px;

//   color: #111111;
//   text-decoration: none;
//   text-decoration: bold;
//   font-weight: 700;
//   font-size: 14px;
//   border-left: 6px solid #e44331;
//   padding-left: 8px;
//   ${props =>
//     props.type == "H2" ? `padding-left: 8px;` : `padding-left: 40px;`}
//   margin: 4px 0;
// `

const Table = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 16px auto;
  padding: 24px;
  overflow: hidden;
  background: #f3f3f3;
  transition: height 1s, opacity 0.5s, box-shadow 0.9s;
  margin-bottom: 64px;
  margin-top: 64px;
`

const Content = styled.a`
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #111111;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  padding-left: 8px;
  ${props =>
    props.type == "H2" ? `padding-left: 8px;` : `padding-left: 40px;`}
  margin: 4px 0;
  :hover {
    color: #e44331;
  }
`

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-transform: capitalize;
  margin-bottom: 24px;

  color: #36454f;
  :hover {
    cursor: pointer;
  }
`
function ContentTable({ content, contentType }) {
  useEffect(() => {
    let parent = document.getElementById("sticky").parentElement
    const table = document.getElementById("sticky")
    const title = document.getElementById("title")

    let tableSize = table.clientHeight + "px"

    window.setTimeout(() => {
      tableSize = table.clientHeight + "px"
    }, 1)

    title.addEventListener("click", () => {
      console.log("title")

      if (table.style.height != tableSize) {
        table.style.height = tableSize
        table.style.animation = "none"
      } else {
        table.style.height = "78px"
        table.style.animation = `shadow 1.5s alternate infinite`
      }
    })

    while (parent) {
      const hasOverflow = getComputedStyle(parent).overflow
      if (hasOverflow !== "visible") {
        console.log(hasOverflow, parent)
      }
      parent = parent.parentElement
    }
  }, [])
  let first = 0
  let second = 0

  return (
    <Table id="sticky">
      <Title id="title">Jump To The section</Title>
      {content?.map((a, i) => {
        if (contentType[i] == "H3") {
          second += 1
        } else {
          first += 1
          second = 0
        }
        return (
          <Content
            type={contentType[i]}
            href={`#${a}`}
            onClick={() => {
              let title = document.getElementById(a)
              window.setTimeout(
                () => window.scrollTo(window.scrollX, title.offsetTop - 100),
                1
              )
            }}
          >
            {`${
              contentType[i] == "H2"
                ? first + ". " + a
                : first + "." + second + ". " + a
            }`}
          </Content>
        )
      })}
    </Table>
  )
  //   return <div>ahaaa</div>
}

export default ContentTable

import React, { useState, useEffect } from "react"
import { createContext } from "react"

//creating context
export const GlobalAuthContext = createContext({})

//creating component that will be wrapping the root element to we can use global context on all pages and components
const GlobalContextProvider = ({ children }) => {
  //Closeable Sticky Bar
  const [closedSticky, setClosedSticky] = useState(null)
  //global login status
  const [isLoggedIn, setIsLoggedIn] = useState(null)
  //username for dashboard navbar
  const [user, setUser] = useState("")
  //state that keeps track of how many licenses does user have - active ones
  const [authNumber, setAuthNumber] = useState(null)
  //state that gives information if user is primary administrator aka customer
  const [globalPrimary, setGlobalPrimary] = useState(null)
  //basic variable that stores which licence type is active - by default it has value 1 - PDF Pro
  const [activee, setActivee] = useState(1)
  //checkout process
  const [step, setStep] = useState(0)
  const [complete, setComplete] = useState(0)
  //dropdown button
  const [toggle, setToggle] = useState(false)
  //show/hide side menu
  const [sideMenu, setSideMenu] = useState(false)
  //info moadal
  const [showInfo, setShowInfo] = useState(true)
  //checking set update
  const [upgrade, setUpgrade] = useState(false)

  //special just for checkout context sync
  //states that checks number of licenses
  const [number, setNumber] = useState(null)
  //loader state
  const [loader, setLoader] = useState(undefined)
  const [activeLicenses, setActiveLicenses] = useState(null)
  const [licenses, setLicenses] = useState(null)
  const [plan, setPlan] = useState(null)
  const [planType, setPlanType] = useState(() => "")
  const [subscriptionPeriod, setSubscriptionPeriod] = useState(() => "")
  const [scroll, setScroll] = useState(false)
  const [userStatus, setUserStatus] = useState(undefined)
  const [isLoadingApp, setIsLoadingApp] = useState(true)

  //  country tags
  const [selectedOption, setSelectedOption] = useState(null)

  useEffect(() => {
    if (localStorage.getItem("username")) {
      setUser(JSON.parse(localStorage.getItem("username")).first_name)
      setIsLoggedIn(true)
    } else {
      setIsLoggedIn(false)
    }
  }, [user])

  return (
    <GlobalAuthContext.Provider
      value={{
        closedSticky,
        setClosedSticky,
        isLoggedIn,
        setIsLoggedIn,
        user,
        setUser,
        authNumber,
        setAuthNumber,
        globalPrimary,
        setGlobalPrimary,
        activee,
        setActivee,
        complete,
        setComplete,
        step,
        setStep,
        toggle,
        setToggle,
        sideMenu,
        setSideMenu,
        number,
        setNumber,
        loader,
        setLoader,
        activeLicenses,
        setActiveLicenses,
        plan,
        setPlan,
        licenses,
        setLicenses,
        planType,
        setPlanType,
        subscriptionPeriod,
        setSubscriptionPeriod,
        scroll,
        setScroll,
        userStatus,
        setUserStatus,
        isLoadingApp,
        setIsLoadingApp,
        showInfo,
        setShowInfo,
        upgrade,
        setUpgrade,
        selectedOption,
        setSelectedOption,
      }}
    >
      {children}
    </GlobalAuthContext.Provider>
  )
}

export default GlobalContextProvider

import React from "react"
import GlobalContextProvider from "./src/Contexts/GlobalAuthContext"
import Layout from "./src/components/layout"
import ErrorBoundary from "./src/components/shared/errorBoundary"
import Helmet from "react-helmet"
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews"
import { linkResolver } from "./src/utils/linkResolver"
import BlogTemplate from "./src/templates/blogpost.js"
import data from "./branch.json"
import MacPopup from "./src/components/shared/macPopup"

//docs you used for this logic --> https://dev.to/crock/gatsby-js-browser-apis-explained-wrappageelement-vs-wraprootelement-n4k

//gatsby docs says that wrapRootElement is for providers
export const wrapRootElement = ({ element }) => {
  return (
    <ErrorBoundary>
      <GlobalContextProvider>
        <PrismicPreviewProvider
          repositoryConfigs={[
            {
              repositoryName: process.env.PRISMIC_REPOSITORY,
              linkResolver,
              componentResolver: componentResolverFromMap({
                pdfpro_post: BlogTemplate,
              }),
            },
          ]}
        >
          {element}
        </PrismicPreviewProvider>
      </GlobalContextProvider>
    </ErrorBoundary>
  )
}

//gatsby docs says that wrapPageElement is for layouts
export const wrapPageElement = ({ element, props }) => {
  const pagesWithPdfJS =
    "merge-pdf,rotate-pdf,split-pdf,extract-pdf-pages,delete-pdf-pages,password-protect-pdf,pdf-to-jpg,pdf-to-png,pdf-to-tiff,pdf-to-bmp,rearrange-pdf-pages,bmp-to-pdf,png-to-pdf,jpg-to-pdf,tiff-to-pdf,pdf-to-excel,pdf-to-word,pdf-online-tools,pdf-to-powerpoint,pdf-to-html,word-to-pdf, excel-to-pdf, pdf-to-csv, pdf-to-xml, powerpoint-to-pdf"
  const url = props.path.replaceAll("/", "")

  return (
    <ErrorBoundary>
      <Helmet>
        {pagesWithPdfJS.includes(url) && url.length > 1 && (
          // <script
          //   type="text/javascript"
          //   src="https://unpkg.com/pdfjs-dist/build/pdf.worker.js"
          // ></script>
          <script
            src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.3.122/pdf.worker.min.js"
            integrity="sha512-Am3j2LUWSl4PsujPgLgHi4kztn5rVozTiX9YkaSuwzYCOIumNISgFBRh5Ux6ZWFB5+Cu5buNtbNwmaWLteyB9w=="
            crossorigin="anonymous"
            referrerpolicy="no-referrer"
          ></script>
        )}
        {pagesWithPdfJS.includes(url) && url.length > 1 && (
          // <script
          //   type="text/javascript"
          //   src="https://unpkg.com/pdfjs-dist/build/pdf.js"
          // ></script>
          <script
            src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.3.122/pdf.min.js"
            integrity="sha512-CGtesFWoCAxW8xV1S4wdev6uWmGhkEBtTGJsQLkT75ab0eVyx0RTOdGxHk9hFVV/OlF6ZyCoukfPdiZPpAiUtw=="
            crossorigin="anonymous"
            referrerpolicy="no-referrer"
          ></script>
        )}
        <script type="text/javascript">
          {`
           (function(w,d,t,r,u)
           {
             var f,n,i;
             w[u]=w[u]||[],f=function()
             {
               var o={ti:"4076835", enableAutoSpaTracking: true};
               o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
             },
             n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
             {
               var s=this.readyState;
               s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
             },
             i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
           })
           (window,document,"script","//bat.bing.com/bat.js","uetq");
        `}
        </script>
        <script>
          {`
          function decorateURL(url) {
            var linkerParam = null
            if (typeof ga === "function" && ga) {
              ga(function () {
                var trackers = ga.getAll()
                linkerParam = trackers[0].get("linkerParam")
              })
            }

            function getCookie(cname) {
              let name = cname + "=";
              let decodedCookie = decodeURIComponent(document.cookie);
              let ca = decodedCookie.split(';');
              for(let i = 0; i <ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                  c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                  return c.substring(name.length, c.length);
                }
              }
              return "";
            }
            let cookies = getCookie("source");

            if(linkerParam && cookies){
              return url + "?" + linkerParam + "&source=" + cookies;
            }
            else if(!linkerParam && cookies){
              return url + "?source=" + cookies;
            }
            else if(linkerParam && !cookies){
              return url + "?" + linkerParam;
            }
            else{
              return url;
            }
          }`}
        </script>
        {data.currentBranch == "staging" ? (
          <script>
            {`
          function dataPopupClosed(data) {
          console.log("Popup: ", data)

          function getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i <ca.length; i++) {
              let c = ca[i];
              while (c.charAt(0) == ' ') {
                c = c.substring(1);
              }
              if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
              }
            }
            return "";
          }

          function deleteCookie(name) {   
            document.cookie = name+'=; Max-Age=-99999999;';  
        }

          if(getCookie("source") && getCookie("utm_source") && getCookie("utm_content") && getCookie("utm_medium") && getCookie("utm_campaign") && getCookie("utm_term")){
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: "TestPaidTransaction",
              category: "Purchase through ppc search - test",
              action: "Successfull purchase - ppc search - test",
              label: JSON.stringify({
                source: getCookie("source"),
                utm_source: getCookie("utm_source"),
                utm_medium: getCookie("utm_medium"),
                utm_term: getCookie("utm_term"),
                utm_content: getCookie("utm_content"),
                utm_campaign: getCookie("utm_campaign"),
                source_trial: getCookie("source_trial"),
                order_id: data.id,
                order_reference:data.reference,
              },null,10)
            })
            deleteCookie("source")
            deleteCookie("utm_source")
            deleteCookie("utm_content")
            deleteCookie("utm_medium")
            deleteCookie("utm_term")
            deleteCookie("utm_campaign")
          }
          else{
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "TestOrganicTransaction",
            category: "Purchase through organic search - test",
            action: "Successfull purchase - organic search - test",
            label: JSON.stringify({
              source: "organic",
              source_trial: getCookie("source_trial"),
              order_id: data.id,
              order_reference:data.reference,
            },null,10)
          })}
          }`}
          </script>
        ) : (
          <script>
            {`
        function dataPopupClosed(data) {
          console.log("Popup: ", data)

        function getCookie(cname) {
          let name = cname + "=";
          let decodedCookie = decodeURIComponent(document.cookie);
          let ca = decodedCookie.split(';');
          for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
              return c.substring(name.length, c.length);
            }
          }
          return "";
        }

        function deleteCookie(name) {   
          document.cookie = name+'=; Max-Age=-99999999;';  
      }

        if(getCookie("source") && getCookie("utm_source") && getCookie("utm_content") && getCookie("utm_medium") && getCookie("utm_campaign") && getCookie("utm_term")){
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "PaidTransaction",
            category: "Purchase through ppc search",
            action: "Successfull purchase - ppc search",
            label: JSON.stringify({
              source: getCookie("source"),
              utm_source: getCookie("utm_source"),
              utm_medium: getCookie("utm_medium"),
              utm_term: getCookie("utm_term"),
              utm_content: getCookie("utm_content"),
              utm_campaign: getCookie("utm_campaign"),
              source_trial: getCookie("source_trial"),
              order_id: data.id,
              order_reference:data.reference,
            },null,10)
          })
          deleteCookie("source")
          deleteCookie("utm_source")
          deleteCookie("utm_content")
          deleteCookie("utm_medium")
          deleteCookie("utm_term")
          deleteCookie("utm_campaign")
        }
        else{
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "OrganicTransaction",
          category: "Purchase through organic search",
          action: "Successfull purchase - organic search",
          label: JSON.stringify({
            source: "organic",
            source_trial: getCookie("source_trial"),
            order_id: data.id,
            order_reference:data.reference,
          },null,10)
        })}
        }`}
          </script>
        )}
        {/* test storefront popup */}
        {data.currentBranch == "staging" ? (
          <script
            id="fsc-api"
            src="https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.5/fastspring-builder.min.js"
            type="text/javascript"
            data-access-key="HKWR3VNHQBI_-NTIOYAPRQ"
            data-storefront="pdf-pro.test.onfastspring.com/popup-pdf-pro"
            data-popup-webhook-received="paymentReceived"
            data-decorate-callback="decorateURL"
            data-popup-closed="dataPopupClosed"
            defer
          ></script>
        ) : (
          /*online storefront popup  */
          <script
            id="fsc-api"
            src="https://d1f8f9xcsvx3ha.cloudfront.net/sbl/0.8.5/fastspring-builder.min.js"
            type="text/javascript"
            data-storefront="pdf-pro.onfastspring.com/popup-pdf-pro"
            // you added data-access-key
            data-access-key="HKWR3VNHQBI_-NTIOYAPRQ"
            data-popup-webhook-received="paymentReceived"
            data-decorate-callback="decorateURL"
            data-popup-closed="dataPopupClosed"
          ></script>
        )}
      </Helmet>
      <Layout
        wrapLocation={props.location}
        onLp={
          props.location.pathname.includes("lp") ||
          props.location.pathname.includes("rm")
        }
        onTool={pagesWithPdfJS.includes(url) && url.length > 1}
        onCheckout={props.location.pathname.includes("checkout")}
        location={props.location}
      >
        {element}
      </Layout>
    </ErrorBoundary>
  )
}

import React from "react"
import styled from "styled-components"
// import Logo from "../../icons/PDF-logo-vector.svg"
import Logo from "../../icons/logo-vector-white.svg"
import Logo2 from "../../icons/logo-vector-white2.svg"

import Payments from "../../icons/tools/paymentMethods.svg"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Merge from "./icons/Merge.svg"
import Rotate from "./icons/Rotate.svg"
import Extract from "./icons/Extract.svg"
import Delete from "./icons/Delete.svg"
import Rearrange from "./icons/Rearrange.svg"
import Split from "./icons/Split.svg"
import Image from "./icons/Image.svg"
import Protect from "./icons/Protect.svg"

const FooterHolder = styled.div`
  background: #222222;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
`

const Payment = styled.div`
  margin-top: bottom;
`

const L = styled(Link)`
  font-size: 15px;
  line-height: 24px;
  color: #999999;
  cursor: pointer;
  margin-bottom: 8px;
  text-decoration: none;
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`

const Adress = styled.div`
  font-size: 15px;
  line-height: 24px;
  color: #999999;
  max-width: 167px;
  margin-bottom: 8px;
`
const SectionTitle = styled.div`
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 12px;
`
const Footer = styled.div`
  max-width: 1440px;
  width: 90%;
`

const UpperFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  /* flex-wrap: wrap; */
`

const BottomFooter = styled.div`
  font-size: 13px;
  line-height: 22px;
  text-transform: capitalize;
  color: #999999;
  margin-bottom: 16px;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 18%;
  /* margin-right: 16px; */
  min-height: 220px;
  @media (max-width: 1420px) {
    min-width: 15%;
  }

  @media (max-width: 934px) {
    min-height: 240px;
  }
  @media (max-width: 1243px) {
    max-height: 240px;
  }
  @media (max-width: 400px) {
    min-width: max-content;
  }
`

const Email = styled.a`
  font-size: 15px;
  line-height: 24px;
  /* text-decoration: none; */
  color: #999999;
  margin-bottom: 12px;
  text-underline-position: under;
`
const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 32px;

  @media (max-width: 980px) {
    gap: 32px;
  }
  @media (max-width: 400px) {
    gap: 16px;
  }
  @media (max-width: 680px) {
    .special {
      min-width: 50%;
    }
  }
  @media (max-width: 550px) {
    .special {
      min-width: 100%;
      min-height: 128px;
    }
  }
`
function ToolFooter() {
  return (
    <FooterHolder>
      <Footer>
        <UpperFooter>
          <Row>
            <Column>
              <SectionTitle>View & Edit</SectionTitle>
              <L to="/merge-pdf">
                <Merge /> Merge PDF
              </L>
              <L to="/rotate-pdf">
                <Rotate /> Rotate PDF
              </L>
              <L to="/extract-pdf-pages">
                <Extract /> Extract PDF Pages
              </L>
              <L to="/delete-pdf-pages">
                <Delete /> Delete PDF Pages
              </L>
              {/* <L to="/rearrange-pdf">
                <Rearrange /> Rearrange Pages
              </L> */}
              <L to="/split-pdf">
                <Split /> Split PDF
              </L>
            </Column>
            <Column>
              <SectionTitle>Convert from PDF</SectionTitle>
              <L to="/pdf-to-png">
                <Image /> PDF to PNG
              </L>
              <L to="/pdf-to-jpg">
                <Image /> PDF to JPG
              </L>
              <L to="/pdf-to-tiff">
                <Image /> PDF to TIFF
              </L>
              <L to="/pdf-to-bmp">
                <Image /> PDF to BMP
              </L>
            </Column>
            <Column>
              <SectionTitle>Convert from PDF</SectionTitle>
              {/* <L to="/pdf-to-word">
                <Image /> PDF to Word
              </L> */}
              <L to="/pdf-to-excel">
                <Image /> PDF to Excel
              </L>
              {/* <L to="/pdf-to-ppt">
                <Image /> PDF to PPT
              </L>
              <L to="/pdf-to-html">
                <Image /> PDF to HTML
              </L> */}
            </Column>
            <Column>
              <SectionTitle>Convert from PDF</SectionTitle>
              <L to="/png-to-pdf">
                <Merge /> PNG to PDF
              </L>
              <L to="/jpg-to-pdf">
                <Merge /> JPG to PDF
              </L>
              <L to="/tiff-to-pdf">
                <Merge /> TIFF to PDF
              </L>
              <L to="/bmp-to-pdf">
                <Merge /> BMP to PDF
              </L>
            </Column>
            <Column className="special">
              <SectionTitle>Security</SectionTitle>
              <L to="/password-protect-pdf">
                <Protect /> Protect PDF
              </L>
            </Column>

            <Column>
              <SectionTitle>PDF Pro Software Inc.</SectionTitle>
              <Adress>
                1383 W. 8th Avenue <br /> Vancouver, B.C.
                <br /> V6H 3W4, Canada
              </Adress>
              <Email href="mailto: hello@pdfpro.com">hello@pdfpro.com</Email>
              <Logo />
            </Column>

            {/* <Column>
              <SectionTitle>PDF Pro Software Inc.</SectionTitle>
              <Adress>
                1383 W. 8th Avenue <br /> Vancouver, B.C. <br /> V6H 3W4, Canada
              </Adress>
              <Email href="mailto: hello@pdfpro.com">hello@pdfpro.com</Email>
              <Logo2 />
            </Column> */}
            {/* <Column>
              <SectionTitle>Products</SectionTitle>
              <L to="/">PDF Pro</L>
              <L to="/pdf-online-tools">Online Tools</L>
            </Column> */}
            <Column>
              <Payment>
                <SectionTitle>Payments</SectionTitle>
                <Payments />
              </Payment>
            </Column>
            <Column>
              <SectionTitle>Support</SectionTitle>
              <L
                to="https://support.pdfpro.com/hc/en-us/categories/900000414286-Knowledge-Base"
                rel="noreferrer noopener"
                target="_blank"
              >
                Knowledge
              </L>
              <L
                to="https://support.pdfpro.com/hc/en-us/requests/new"
                rel="noreferrer noopener"
                target="_blank"
              >
                Submit a Ticket
              </L>
              <L
                to="https://support.pdfpro.com/hc/en-us/sections/4407459740569-FAQ"
                rel="noreferrer noopener"
                target="_blank"
              >
                FAQ
              </L>
              <L
                to="https://support.pdfpro.com/hc/en-us/sections/900002105743-Release-Notes"
                rel="noreferrer noopener"
                target="_blank"
              >
                Release Notes
              </L>
            </Column>

            <Column>
              <SectionTitle>Company</SectionTitle>
              <L to="/about">About Us</L>
              <L to="/careers">Careers</L>
              <L to="/blog">Blog</L>
              <L>Member of</L>
              <Link
                className="PDFA"
                to="https://www.pdfa.org/member/pdf-pro-software-inc"
              >
                <StaticImage
                  style={{ marginLeft: "0" }}
                  src="../../images/tools/PDFAtools.png"
                  alt="PDFA"
                  quality={100}
                  width={64}
                />
              </Link>
            </Column>
            <Column>
              <SectionTitle>Policy</SectionTitle>
              <L to="/licensing">Licence Information</L>
              <L to="/dpa">DPA</L>
              <L to="/terms">Terms and Conditions</L>
              <L to="/cookies">Cookie Policy</L>
              <L to="/privacy">Privacy Policy</L>
              <L to="/refund-policy">Refund Policy</L>
            </Column>
          </Row>
        </UpperFooter>
        <BottomFooter>
          PDF Pro is a desktop software for Windows distributed by PDF Pro
          Software Inc. The software PDF Pro and the company "PDF Pro Software
          Inc. are in no way affiliated with Adobe. The PDF file format is an
          open file format published under ISO 32000-1:2008
        </BottomFooter>
        <BottomFooter>
          © 2017-{new Date().getFullYear()}, PDF Pro Software Inc. All rights
          reserved.
        </BottomFooter>
      </Footer>
    </FooterHolder>
  )
}

export default ToolFooter

import React from "react"
import styled from "styled-components"
// import Logo from "../../icons/PDF-logo-vector.svg"
import Logo from "../../icons/logo-vector-white.svg"
import Logo2 from "../../icons/logo-vector-white2.svg"

import Payments from "../../icons/tools/paymentMethods.svg"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const FooterHolder = styled.div`
  background: #222222;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
`

const Payment = styled.div`
  margin-top: bottom;
`

const L = styled(Link)`
  font-size: 15px;
  line-height: 24px;
  color: #999999;
  cursor: pointer;
  margin-bottom: 8px;
  text-decoration: none;
`
const SubL = styled(L)`
  :before {
    content: "• ";
  }
`

const Adress = styled.div`
  font-size: 15px;
  line-height: 24px;
  color: #999999;
  max-width: 167px;
  margin-bottom: 8px;
`
const SectionTitle = styled.div`
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 12px;
`
const Footer = styled.div`
  max-width: 1440px;
  width: 90%;
`

const UpperFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  /* flex-wrap: wrap; */

  .policy {
    margin-right: 32px;
  }

  .info {
    @media (max-width: 1260px) {
      margin-right: 24px;
    }
    @media (max-width: 1243px) {
      margin-bottom: 240px;
    }
  }

  @media (max-width: 1243px) {
    .products {
      order: 3;
      margin-right: 0;
      max-width: 100px;
      min-width: 100px;
      min-width: 167px;
    }
    .policy {
      order: 2;
    }
    .support {
      padding-top: 68px;
      margin-right: 32px;
      order: 6;
      /* margin-right: 200px; */
    }
    .payments {
      order: 1;
      margin-right: 0;
      max-width: 100px;
      min-width: 100px;
      min-width: 167px;
    }
    .company {
      order: 5;
      padding-top: 68px;
      margin-right: 0;
    }

    .tools {
      margin-right: 0;
    }

    margin-bottom: 48px;
  }
  @media (max-width: 1042px) {
    .payments {
      order: 3;
      margin-right: 16px;
    }
    .support {
      order: 4;
      margin-right: 16px;
      padding-top: 68px;
      margin: 0;
    }
    .policy {
      margin: 0;
      margin-right: 16px;
    }
    .products {
      order: 1;
    }
    .tools {
      order: 3;
      padding-top: 68px;
    }
  }

  @media (max-width: 836px) {
    .support {
      order: 4;
    }
    .payments {
      order: 2;
    }
    .company {
      order: 5;
      padding-top: 68px;
    }
    .products {
      margin-right: 16px;
      max-width: 400px;
      min-width: 167px;
    }
  }

  @media (max-width: 819px) {
    .products {
      margin: 0;
    }
  }
  @media (max-width: 801px) {
    .support {
      padding-top: 68px;
    }
  }

  .info-mobile {
    display: none;
  }
  @media (max-width: 660px) {
    min-width: 440px;
    margin-bottom: 0;

    .policy {
      margin-right: 16px;
    }
    .support {
      order: 3;
      padding-top: 0;
      margin-top: 32px;
    }
    .payments {
      order: 1;
      padding-top: 0;
      /* margin-top: 32px; */
    }
    .company {
      order: 5;
      padding-top: 0;
      margin-top: 32px;
    }
    .info-mobile {
      display: flex;
    }
    .info {
      display: none;
    }
  }
  @media (max-width: 621px) {
    .policy {
      margin-right: 20%;
      margin-top: 32px;
    }
    .tools {
      margin-right: 20%;
      order: 3;
    }
    .products {
      order: 5;
      margin: 0;
      padding-top: 32px;
    }

    .support {
      order: 1;
      margin: 0;
    }
    .company {
      order: 3;
    }
    .info-mobile {
      margin-right: 20%;
    }
    .payments {
      margin-right: 20%;
      order: 2;
    }
    .products {
      order: 2;
      padding-top: 0;
    }
  }

  @media (max-width: 440px) {
    .policy {
      margin-right: 16px;
      margin-top: 32px;
    }
    .info-mobile {
      margin-right: 16px;
    }
    .payments {
      margin-right: 16px;
    }
    .tools {
      margin-right: 16px;
    }
  }

  @media (max-width: 350px) {
    min-width: 0;
    .products {
      margin-top: 32px;
      padding: 0;
    }
    .support {
      margin-top: 32px;
    }
    .payments {
      padding-top: 32px;
      margin-bottom: 32px;
    }
  }
`

const BottomFooter = styled.div`
  font-size: 13px;
  line-height: 22px;
  text-transform: capitalize;
  color: #999999;
  margin-bottom: 16px;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 167px;
  margin-right: 16px;
  min-height: 220px;
  @media (max-width: 934px) {
    min-height: 240px;
  }
  @media (max-width: 1243px) {
    max-height: 240px;
  }
  @media (max-width: 350px) {
    min-height: 0;
  }
`

const Email = styled.a`
  font-size: 15px;
  line-height: 24px;
  /* text-decoration: none; */
  color: #999999;
  margin-bottom: 12px;
  text-underline-position: under;
`
const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 934px) {
    max-width: 600px;
  }
  @media (max-width: 1243px) {
    /* justify-content: center; */
    max-width: 540px;

    flex-direction: row-reverse;
  }
  @media (max-width: 836px) {
    flex-direction: row;
  }
`
function NewFooter() {
  return (
    <FooterHolder>
      <Footer>
        <UpperFooter>
          <Column className="info">
            <SectionTitle>PDF Pro Software Inc.</SectionTitle>
            <Adress>
              1383 W. 8th Avenue <br /> Vancouver, B.C.
              <br /> V6H 3W4, Canada
            </Adress>
            <Email href="mailto: hello@pdfpro.com">hello@pdfpro.com</Email>
            <Logo />
          </Column>

          <Row>
            <Column className="info-mobile">
              <SectionTitle>PDF Pro Software Inc.</SectionTitle>
              <Adress>
                1383 W. 8th Avenue <br /> Vancouver, B.C. <br /> V6H 3W4, Canada
              </Adress>
              <Email href="mailto: hello@pdfpro.com">hello@pdfpro.com</Email>
              <Logo2 />
            </Column>
            <Column className="payments">
              <SectionTitle>Products</SectionTitle>
              <L to="/">PDF Pro</L>
              <L to="/pdf-online-tools">Online Tools</L>
              <SubL to="/merge-pdf">Merge PDF</SubL>
              <SubL to="/rotate-pdf">Rotate PDF</SubL>
              <SubL to="/pdf-to-png">PDF to PNG</SubL>
              <SubL to="/pdf-to-jpg">PDF to JPG</SubL>
              <L to="/pdf-online-tools">
                <u>All Online Tools</u>
              </L>
            </Column>
            <Column className="products">
              <Payment>
                <SectionTitle>Payments</SectionTitle>
                <Payments />
              </Payment>
            </Column>
            <Column className="support">
              <SectionTitle>Support</SectionTitle>
              <L
                to="https://support.pdfpro.com/hc/en-us/categories/900000414286-Knowledge-Base"
                rel="noreferrer noopener"
                target="_blank"
              >
                Knowledge
              </L>
              <L
                to="https://support.pdfpro.com/hc/en-us/requests/new"
                rel="noreferrer noopener"
                target="_blank"
              >
                Submit a Ticket
              </L>
              <L
                to="https://support.pdfpro.com/hc/en-us/sections/4407459740569-FAQ"
                rel="noreferrer noopener"
                target="_blank"
              >
                FAQ
              </L>
              <L
                to="https://support.pdfpro.com/hc/en-us/sections/900002105743-Release-Notes"
                rel="noreferrer noopener"
                target="_blank"
              >
                Release Notes
              </L>
            </Column>
            <Column className="policy">
              <SectionTitle>Policy</SectionTitle>
              <L to="/licensing">Licence Information</L>
              <L to="/dpa">DPA</L>
              <L to="/terms">Terms and Conditions</L>
              <L to="/cookies">Cookie Policy</L>
              <L to="/privacy">Privacy Policy</L>
              <L to="/refund-policy">Refund Policy</L>
            </Column>

            <Column className="company">
              <SectionTitle>Company</SectionTitle>
              <L to="/about">About Us</L>
              <L to="/careers">Careers</L>
              <L to="/blog">Blog</L>
              <L>Member of</L>
              <Link
                className="PDFA"
                to="https://www.pdfa.org/member/pdf-pro-software-inc"
              >
                <StaticImage
                  style={{ marginLeft: "0" }}
                  src="../../images/tools/PDFAtools.png"
                  alt="PDFA"
                  quality={100}
                  width={64}
                />
              </Link>
            </Column>
          </Row>
        </UpperFooter>
        <BottomFooter>
          PDF Pro is a desktop software for Windows distributed by PDF Pro
          Software Inc. The software PDF Pro and the company "PDF Pro Software
          Inc. are in no way affiliated with Adobe. The PDF file format is an
          open file format published under ISO 32000-1:2008
        </BottomFooter>
        <BottomFooter>
          © 2017-{new Date().getFullYear()}, PDF Pro Software Inc. All rights
          reserved.
        </BottomFooter>
      </Footer>
    </FooterHolder>
  )
}

export default NewFooter
